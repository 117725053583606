import { Box } from '@ccsdk/kingbolt';

export const PhaseStatusContainer = (props) => <Box sx={{ position: 'relative', display: 'inline-flex' }} {...props} />;

export const PhaseStatusCount = (props) => (
  <Box
    sx={{
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    {...props}
  />
);
