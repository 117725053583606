import styled from '@emotion/styled';
import { Icon, IconButton } from '@ccsdk/kingbolt';

export const InfoIcon = () => <Icon color="info" type="infofilled" />;
export const WarnIcon = () => <Icon color="warning" type="warningfilled" />;
export const ErrorIcon = () => <Icon color="error" type="alertfilled" />;
export const LogIcon = () => <Icon type="info" />;
export const DebugIcon = () => <Icon type="infofilled" />;
export const LocationIcon = () => <Icon type="sharefilled" />;
export const PermissionIcon = (props) => <Icon type="lockeduserfilled" {...props} />;
export const CoreIcon = () => <Icon type="expandviewfilled" />;
export const WebsocketsIcon = () => <Icon type="connectionfilled" />;

export const StyledIconButton = styled(IconButton)`
  &&.MuiButtonBase-root {
    color: ${({ theme: { palette } }) => palette.grey[0]} !important;
  }

  &&.MuiButtonBase-root:hover {
    background-color: ${({ theme: { palette } }) => palette.grey[600]};
  }
`;
