import { useEffect } from 'react';
import { array, bool } from 'prop-types';
import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import NoRouteMatch from '@ccsdk/core/components/NoRouteMatch';
import UnauthorizedRouteMatch from '@ccsdk/core/components/UnauthorizedRouteMatch';
import BootChecker from '~/components/BootChecker';
import DynamicModuleLoader from '~/components/Routing/DynamicModuleLoader';
import NavigationManager from '~/components/Routing/NavigationManager';
import { withProfiler, withSentryReactRouterV6Routing } from '@sentry/react';

const WrappedRoutes = withSentryReactRouterV6Routing(ReactRouterRoutes);

const renderModuleRoute = ({ name, path, authorized, wrappedComponent }) => {
  if (!wrappedComponent) {
    throw new Error(`Module "${name}" has path "${path}" but no component`);
  }

  if (!authorized) {
    return <Route element={<UnauthorizedRouteMatch />} path={`${path}/*`} />;
  }

  return <Route element={wrappedComponent} key={name} path={`${path}/*`} />;
};

const Routes = ({ federatedTools, federatedToolsLoading }) => {
  useEffect(() => {
    if (federatedToolsLoading === false) {
      try {
        window.removeLottie(1000);
      } catch (error) {
        console.warn('App Shell', 'Routes', 'Failed to remove Lottie');
      }
    }
  }, [federatedToolsLoading]);

  const renderMain = () => {
    if (federatedToolsLoading) {
      return null;
    }

    return (
      <>
        {federatedTools?.map(renderModuleRoute)}
        <Route element={<NoRouteMatch />} path="*" />
      </>
    );
  };

  return (
    <>
      <NavigationManager />
      <DynamicModuleLoader />
      <WrappedRoutes>{renderMain()}</WrappedRoutes>
      <BootChecker />
    </>
  );
};

Routes.propTypes = {
  federatedTools: array,
  federatedToolsLoading: bool,
};

export default withProfiler(Routes, { name: 'Routes' });
