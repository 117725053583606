import { useCallback, useMemo } from 'react';
import { LocationEntry, LogEntry, logType, PermissionEntry } from '@ccsdk/development-debug-utils';
import {
  applySubstitutionToMessage,
  templateContainsSubstitutionPattern,
} from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/LogEntryRow/LogMessage/SubstitutionMessage/utils';

// TODO: identify more core logging
const CORE_LOG_TAGS = ['Core', 'App Shell', 'Dashboard', 'Adobe Analytics', 'Debug Utils', 'websocket', 'next gen websocket'];

const { LOG, INFO, DEBUG, ERROR, WARN } = logType;

export const useFilter = (filter) => {
  const logFilterSettings = useMemo(
    () => ({
      [LOG]: filter.log,
      [INFO]: filter.info,
      [DEBUG]: filter.debug,
      [ERROR]: filter.error,
      [WARN]: filter.warning,
    }),
    [filter],
  );

  return useCallback(
    (entry) => {
      if (entry.constructor === LogEntry) {
        if (!logFilterSettings[entry.type]) {
          return false;
        }

        if (!filter.core && CORE_LOG_TAGS.includes(entry.data[0])) {
          return false;
        }
      }

      if (!filter.navigation && entry.constructor === LocationEntry) {
        return false;
      }

      if (filter.text) {
        const lowerCaseFilter = filter.text.toLowerCase();

        switch (entry.constructor) {
          case LogEntry: {
            let logString;

            if (templateContainsSubstitutionPattern(entry.data)) {
              logString = applySubstitutionToMessage(entry.data);
            } else {
              logString = entry.data.join(' ');
            }

            if (filter.caseSensitive) {
              return logString.includes(filter.text);
            }

            return logString.toLowerCase().includes(lowerCaseFilter);
          }
          case PermissionEntry: {
            return entry.permissions.some((permission) => permission?.includes(lowerCaseFilter));
          }
          case LocationEntry: {
            return entry.pathname.includes(lowerCaseFilter) || entry.search.includes(filter.text);
          }
          default:
            break;
        }
      }

      return true;
    },
    [filter, logFilterSettings],
  );
};
