import { useCallback } from 'react';
import { func, string } from 'prop-types';
import { TextField, MenuItem } from '@ccsdk/kingbolt';
import { themeConfigs } from '~/components/DevOverlay/DevOverlayContent/ThemeView/themes';
import ThemePreview from './ThemePreview';

const ThemeSelector = ({ selectedThemeId, onThemeChange = () => {}, ...props } = {}) => {
  const internalOnThemeChange = useCallback(
    ({ target: { value } }) => {
      onThemeChange(value);
    },
    [onThemeChange],
  );

  return (
    <TextField fullWidth onChange={internalOnThemeChange} select value={selectedThemeId} {...props}>
      {Object.entries(themeConfigs).map(([key, { name, colors }]) => {
        return (
          <MenuItem key={key} value={key}>
            {name}
            <ThemePreview colors={colors} />
          </MenuItem>
        );
      })}
    </TextField>
  );
};

ThemeSelector.propTypes = {
  onThemeChange: func,
  selectedThemeId: string,
};

export default ThemeSelector;
