import * as moduleBucket from '@ccsdk/core/utils/moduleBucket';
import {
  LOADED,
  makeSelectAreSomeModulesOfTypeLoading,
  makeSelectDynamicModuleMetaOfType,
  DONT_HAVE_NECESSARY_PERMISSIONS,
} from '@ccsdk/core/ducks/dynamicModules';
import { makeSelectConfig } from '@ccsdk/core/ducks/config';
import { createSelector } from 'reselect';
import { wrapTool } from '~/components/Routing/ToolWrapper';

const selectBaseFederatedTools = makeSelectDynamicModuleMetaOfType('tool');

export const selectFederatedTools = createSelector(selectBaseFederatedTools, (baseFederatedModules) => {
  return baseFederatedModules
    .filter(({ status, type }) => status === LOADED || (type === 'tool' && status === DONT_HAVE_NECESSARY_PERMISSIONS))
    .filter(({ inBucket }) => inBucket)
    .filter(({ key }) => moduleBucket.has(key))
    .map(({ key, authorizedVersions, versionLoaded, versions }) => ({
      path: versions?.[0]?.path,
      authorizedVersions,
      versionLoaded,
      ...moduleBucket.getByKey(key),
    }))
    .map(({ path, module, authorizedVersions, versionLoaded }) => ({
      path,
      authorizedVersions,
      versionLoaded,
      authorized: authorizedVersions?.includes(versionLoaded),
      ...module,
    }))
    .map(wrapTool);
});

export const selectFederatedToolsLoading = makeSelectAreSomeModulesOfTypeLoading('tool');

export const selectStartPath = makeSelectConfig('startPath');
