import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useRootPath from '~/hooks/useRoutePath';
import { selectStartPath } from '~/components/Routing/selectors';
import useInitialSetup from '~/hooks/useInitialSetup';
import { SETUP_PATH } from '~/components/Routing/NavigationManager/constants';

const NavigationManager = () => {
  const startPath = useSelector(selectStartPath);
  const rootPath = useRootPath();
  const navigate = useNavigate();
  const showInitialSetup = useInitialSetup();

  useEffect(() => {
    if (showInitialSetup) {
      console.debug('App Shell', 'Routing', 'NavigationManager', 'Rendering initial setup', SETUP_PATH);
      navigate(SETUP_PATH);
    } else if (!rootPath || rootPath === '/') {
      console.debug('App Shell', 'Routing', 'NavigationManager', 'Rendering startPath', startPath);
      navigate(startPath);
    }
  }, []);

  return null;
};

export default NavigationManager;
