import { Suspense, memo } from 'react';
import { useFederatedModules } from '@ccsdk/core/hooks';
import ErrorBoundary from '@ccsdk/core/components/ErrorBoundary';
import { withProfiler } from '@sentry/react';

export const ServiceLoader = () => {
  const { isLoading, modules = [] } = useFederatedModules('service');

  if (!isLoading && modules?.length) {
    // console.debug('App Shell', 'Services', 'Loading services', modules);
    return modules.map(({ component: Component, name }) => (
      <ErrorBoundary.Empty key={name}>
        <Suspense fallback={null}>
          <Component />
        </Suspense>
      </ErrorBoundary.Empty>
    ));
  }

  return null;
};

export default memo(withProfiler(ServiceLoader, { name: 'ServiceLoader' }));
