import { useCallback, useState } from 'react';
import { string, bool, object, node, func, oneOfType } from 'prop-types';
import { Badge, Icon, Popover, IconButton } from '@ccsdk/kingbolt';

const ButtonPopover = ({ id, children, ButtonComponent = IconButton, badge = false, iconType }) => {
  const [buttonAnchor, setButtonAnchor] = useState(null);
  const open = Boolean(buttonAnchor);

  const onOpen = useCallback(({ currentTarget }) => {
    setButtonAnchor(currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setButtonAnchor(null);
  }, []);

  return (
    <>
      <Badge color="success" invisible={!badge} overlap="circular" variant="dot">
        <ButtonComponent onClick={onOpen}>
          <Icon type={iconType} />
        </ButtonComponent>
      </Badge>
      <Popover
        anchorEl={buttonAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={id}
        onClose={onClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Popover>
    </>
  );
};

ButtonPopover.propTypes = {
  ButtonComponent: oneOfType([object, func]),
  badge: bool,
  children: node,
  iconType: string,
  id: string,
};

export default ButtonPopover;
