import { call, select } from 'redux-saga/effects';
import { selectLoadedStatus } from '@ccsdk/core/ducks/status';
import { renewToken } from './renewToken.saga';

export function* handleVisibilityChange({ value }) {
  const isAppLoaded = yield select(selectLoadedStatus);
  if (isAppLoaded) {
    if (value) {
      console.debug('App Shell', 'Auth', 'handling visibility change', value);
      yield call(renewToken, { skipTokenCheck: true });
    }
  }
}
