import { useMemo } from 'react';
import { oneOf, number, string } from 'prop-types';
import { Box, Icon, Tooltip } from '@ccsdk/kingbolt';
import { PermissionIcon, ErrorIcon, WarnIcon } from '~/components/Debug/DebugPortalWrapper/styles';

const Counter = ({ type, value, tooltip }) => {
  const icon = useMemo(() => {
    switch (type) {
      case 'total':
        return <Icon type="boxesfilled" />;
      case 'permission':
        return <PermissionIcon color="warning" />;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarnIcon />;
      /* istanbul ignore next */
      default:
        return null;
    }
  }, [type]);

  return (
    <Tooltip title={tooltip}>
      <Box alignItems="center" display="flex" gap={1} sx={{ color: ({ palette }) => `${palette.grey[0]} !important` }}>
        {icon}
        <span>{value}</span>
      </Box>
    </Tooltip>
  );
};

Counter.propTypes = {
  tooltip: string,
  type: oneOf(['total', 'permission', 'error', 'warning']),
  value: number,
};

export default Counter;
