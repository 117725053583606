import { useCallback, useMemo } from 'react';
import { func } from 'prop-types';
import { Icon } from '@ccsdk/kingbolt';
import { useDebug } from '@ccsdk/development-debug-utils';
import { StyledButton, CircularProgress } from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/RecordButton/styles';

const RecordButton = ({ onToggleResultDialog }) => {
  const { recording, startRecording, stopRecording } = useDebug();

  const { icon, text } = useMemo(() => {
    if (recording) {
      return { icon: <CircularProgress size={16} />, text: 'Stop recording' };
    }
    return { icon: <Icon type="activate" />, text: 'Start recording' };
  }, [recording]);

  const toggleRecording = useCallback(() => {
    if (recording) {
      stopRecording();
      onToggleResultDialog();
    } else {
      startRecording();
    }
  }, [recording]);

  return (
    <StyledButton icon={icon} onClick={toggleRecording} variant="contained">
      {text}
    </StyledButton>
  );
};

RecordButton.propTypes = {
  onToggleResultDialog: func,
};

export default RecordButton;
