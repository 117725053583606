import { defineMessages } from 'react-intl';

export default defineMessages({
  dialogHeaderTitle: {
    id: 'core.containers.ErrorReporter.dialog.header.title',
    description: 'Title for the Error Reporter Dialog',
    defaultMessage: 'Report issue',
  },
  typeOfIssueTitle: {
    id: 'core.containers.ErrorReporter.typeofissue.title',
    description: 'Title',
    defaultMessage: 'Select type of issue',
  },
  vehicleIssue: {
    id: 'core.containers.ErrorReporter.typeofissue.vehicleissue',
    description: 'Radio button option',
    defaultMessage: 'Vehicle/VIN issue',
  },
  otherIssue: {
    id: 'core.containers.ErrorReporter.typeofissue.otherissue',
    description: 'Radio button option',
    defaultMessage: 'Other issue',
  },
  selectVehicleTitle: {
    id: 'core.containers.ErrorReporter.selectvehicle.title',
    description: 'Title',
    defaultMessage: 'Select vehicle',
  },
  detailsTitle: {
    id: 'core.containers.ErrorReporter.details.title',
    description: 'Title',
    defaultMessage: 'Details',
  },
  contactTitle: {
    id: 'core.containers.ErrorReporter.contact.title',
    description: 'Title',
    defaultMessage: 'Contact information',
  },
  reportSubmitted: {
    id: 'core.containers.ErrorReporter.submitted.title',
    description: 'Title',
    defaultMessage: 'Report submitted',
  },

  errorReporterMainTitle: {
    id: 'core.containers.ErrorReporter.mainTitle',
    description: 'Main title for the Error Reporter dialog',
    defaultMessage: 'Please provide us with details regarding your issue',
  },
  errorReporterIssueTypeLabel: {
    id: 'core.containers.ErrorReporter.issueTypeLabel',
    description: 'the label for the Issue Type dropdown',
    defaultMessage: 'Issue type',
  },
  errorReporterIssueTypeDescription: {
    id: 'core.containers.ErrorReporter.issueTypeDescription',
    description: 'the description for the Issue Type dropdown',
    defaultMessage: 'Choose a description best matching your report to help us better understand your issue',
  },
  errorReporterDetailsLabel: {
    id: 'core.containers.ErrorReporter.detailsLabel',
    description: 'the label for the issue details input',
    defaultMessage: 'Details',
  },
  errorReporterDetailsPlaceholder: {
    id: 'core.containers.ErrorReporter.detailsPlaceholder',
    description: 'the placeholder text for the issue details input',
    defaultMessage: 'Please describe your issue in as much detail as possible. For example, what were you trying to do when it happened?',
  },
  errorReporterActionCancel: {
    id: 'core.containers.ErrorReporter.actionCancel',
    description: 'label for the cancel button',
    defaultMessage: 'Cancel',
  },
  errorReporterActionSubmit: {
    id: 'core.containers.ErrorReporter.actionSubmit',
    description: 'label for the submit button',
    defaultMessage: 'Submit',
  },
  errorReporterActionFinish: {
    id: 'core.containers.ErrorReporter.actionFinish',
    description: 'label for the finish button',
    defaultMessage: 'Finish',
  },
  errorReporterSubmitting: {
    id: 'core.containers.ErrorReporter.submitting',
    description: 'Text to show while submitting',
    defaultMessage: 'Submitting',
  },
  errorReporterError: {
    id: 'core.containers.ErrorReporter.error',
    description: 'Text to show if there was an error ',
    defaultMessage: 'There was an error submitting your report, please try again',
  },
  errorReporterSuccess: {
    id: 'core.containers.ErrorReporter.success',
    description: 'Text to show if the operation succeeded ',
    defaultMessage: 'Thank you for your report. Please provide the following code when speaking to support',
  },
  errorReporterCreatingCode: {
    id: 'core.containers.ErrorReporter.creatingCode',
    description: 'Text to show while generating the error code',
    defaultMessage: 'Generating error code, please wait...',
  },
  errorReporterIssueTypeDisturbance: {
    id: 'core.containers.ErrorReporter.issueTypeDisturbance',
    description: 'Label for the disturbance issue type',
    defaultMessage: 'Disturbance',
  },
  errorReporterIssueTypeOther: {
    id: 'core.containers.ErrorReporter.issueTypeOther',
    description: 'Label for the other issue type',
    defaultMessage: 'Other',
  },
  gdprNotice: {
    id: 'core.containers.ErrorReporter.gdprNotice',
    description: 'Label for the other issue type',
    defaultMessage:
      'PRIVACY NOTICE: By submitting an error report, you consent that your user and session data - including but not limited to system logs, network traffic and user information - may be used for the sole purpose of troubleshooting your reported issue. This information will not be shared with external parties and is automatically deleted within 90 days of creation. For full information see our privacy policy.',
  },
  supportIcon: {
    id: 'core.containers.ErrorReporter.support.icon',
    description: 'Label for the support icon error generator',
    defaultMessage: 'Create support package',
  },
  supportBody: {
    id: 'core.containers.ErrorReporter.support.body',
    description: 'Support package description',
    defaultMessage: 'Create a debug package for your current portal session. This can be used by support to help troubleshoot problems you have reported',
  },
  supportLoading: {
    id: 'core.containers.ErrorReporter.support.loading',
    description: 'Support package description when loading',
    defaultMessage: 'Working, please wait',
  },
  supportDone: {
    id: 'core.containers.ErrorReporter.support.done',
    description: 'Support package description when done',
    defaultMessage: 'Package creation complete, please provide the following code to support.',
  },
  supportError: {
    id: 'core.containers.ErrorReporter.support.error',
    description: 'Support package description when error',
    defaultMessage: 'Package creation failed, please try again in a moment.',
  },
});
