import { useState } from 'react';
import { bool, func } from 'prop-types';
import { Dialog, DialogContent, Header, HeaderTitle, Body, Footer, IconButton, Button, Icon, Box } from '@ccsdk/kingbolt';
import ResultTable from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable';
import ResultFilter from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultFilter';
import { DialogPaper } from '~/components/Debug/DebugPortalWrapper/ResultDialog/styles';
import ProfilingResult from '~/components/Debug/DebugPortalWrapper/ResultDialog/ProfilingResult';

const ResultDialog = ({ open, onClose }) => {
  const [filter, setFilter] = useState({
    text: '',
    caseSensitive: false,
    log: true,
    info: true,
    debug: true,
    warning: true,
    error: true,
    permissions: true,
    navigation: true,
    core: false,
  });

  return (
    <Dialog PaperComponent={DialogPaper} open={open}>
      <DialogContent>
        <Header>
          <HeaderTitle>Debug result</HeaderTitle>
          <IconButton onClick={onClose}>
            <Icon type="close" />
          </IconButton>
        </Header>
        <Body disablePadding scroll>
          <Box display="flex" flexDirection="column">
            <ProfilingResult />

            <ResultFilter filter={filter} updateFilter={setFilter} />

            <ResultTable filter={filter} />
          </Box>
        </Body>
        <Footer>
          <Button onClick={onClose} variant="contained">
            Close
          </Button>
        </Footer>
      </DialogContent>
    </Dialog>
  );
};

ResultDialog.propTypes = {
  onClose: func,
  open: bool,
};

export default ResultDialog;
