export const applySubstitutionToMessage = (messageArray) => {
  let substitutedMessage = messageArray[0];

  messageArray.forEach((part, index) => {
    if (index > 0) {
      substitutedMessage = substitutedMessage.replace('%s', part);
    }
  });

  return substitutedMessage;
};

export const splitLongMessages = (message) => {
  const index = message.trim().indexOf('. ');

  if (index !== -1) {
    return {
      short: message.substring(0, index + 1),
      more: message
        .substring(index + 1)
        .trim()
        .replaceAll('\n', '<br />'),
    };
  }

  return { short: message };
};

export const templateContainsSubstitutionPattern = (messageArray) => {
  if (typeof messageArray[0] === 'string') {
    // only %s at the moment
    return messageArray[0].includes('%s') && messageArray.length > 1;
  }
  return false;
};
