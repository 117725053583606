import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useRootPath = () => {
  const location = useLocation();

  return useMemo(() => location.pathname.split('/').filter((item) => !!item)?.[0], [location]);
};

export default useRootPath;
