import { put, call, select } from 'redux-saga/effects';
import { loadTranslations, DEFAULT_LOCALE } from '@ccsdk/core-i18n';
import { changeUserSettings } from '@ccsdk/core/ducks/userSettings';
import { userAuthorized } from '@ccsdk/core/ducks/authentication';
import { getBrowserLocale } from '@ccsdk/core/utils/browserLocale';
import { makeSelectConfig } from '@ccsdk/core/ducks/config';
import { selectQueries } from '@ccsdk/core/ducks/queries';
import { NGPS_NO_AUTH_PERMISSION, NGPS_USER_PERMISSION } from '~/permissions';

export const defaultLocaleSelector = makeSelectConfig('defaultLocale');

export default function* noAuthSaga() {
  const { locale: queryLocale } = yield select(selectQueries);
  const browserLocale = yield call(getBrowserLocale);
  const configLocale = yield select(defaultLocaleSelector);

  if (queryLocale) {
    console.debug('App Shell', 'No Auth', 'Using locale from queries', queryLocale);
  } else if (configLocale) {
    console.debug('App Shell', 'No Auth', 'Using locale from config', configLocale);
  } else if (browserLocale) {
    console.debug('App Shell', 'No Auth', 'Using locale from browser', browserLocale);
  } else {
    console.debug('App Shell', 'No Auth', 'Using default locale', DEFAULT_LOCALE);
  }

  const locale = queryLocale || configLocale || browserLocale || DEFAULT_LOCALE;

  yield call(loadTranslations, locale);

  yield put(
    userAuthorized({
      firstName: 'Anonymous',
      lastName: 'User',
      permissions: [{ permission: NGPS_USER_PERMISSION }, { permission: NGPS_NO_AUTH_PERMISSION }],
    }),
  );

  console.debug('no auth', 'dispatching change user settings', { locale, measurementSystem: 'metric' });
  yield put(changeUserSettings({ locale, measurementSystem: 'metric' }));
}
