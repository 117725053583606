import { Box, Typography } from '@ccsdk/kingbolt';
import { string } from 'prop-types';

export const ProfilerResultContainer = (props) => <Box display="flex" gap={2} padding={2} {...props} />;
export const PhaseResultContainer = (props) => <Box display="flex" gap={4} {...props} />;

export const VerticalText = ({ text }) => (
  <Box height={140} position="relative" width={30}>
    <Box position="absolute" sx={{ transform: 'translateX(-50%) translateY(-50%) rotate(270deg)', top: '50%', left: '50%' }}>
      <Typography sx={{ color: ({ palette }) => palette.grey[0] }} variant="caption">
        {text}
      </Typography>
    </Box>
  </Box>
);

VerticalText.propTypes = {
  text: string,
};
