import { useCallback } from 'react';
import { node, string, func, bool } from 'prop-types';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Switch } from '@ccsdk/kingbolt';

const FilterItem = ({ icon, primary, secondary, onChange, checked }) => {
  const internalOnChange = useCallback(
    ({ target }) => {
      onChange(primary.toLowerCase(), target.checked);
    },
    [primary],
  );

  return (
    <ListItem>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} secondary={secondary} />
      <ListItemSecondaryAction>
        <Switch checked={checked} edge="end" onChange={internalOnChange} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

FilterItem.propTypes = {
  checked: bool,
  icon: node,
  onChange: func,
  primary: string,
  secondary: string,
};

export default FilterItem;
