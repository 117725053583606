import { useMemo } from 'react';
import { object } from 'prop-types';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@ccsdk/kingbolt';
import { useDebug, StartEntry, StopEntry, LocationEntry, PermissionEntry, LogEntry } from '@ccsdk/development-debug-utils';
import StartEntryRow from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/StartEntryRow';
import LocationEntryRow from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/LocationEntryRow';
import LogEntryRow from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/LogEntryRow';
import StopEntryRow from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/StopEntryRow';
import { EmptyCell } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/styles';
import PermissionEntryRow from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/PermissionEntryRow';
import { useFilter } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/useFilter';

const ResultTable = ({ filter }) => {
  const { entries } = useDebug();
  const applyFilter = useFilter(filter);

  const rows = useMemo(
    () =>
      entries.filter(applyFilter).map((entry) => {
        switch (entry.constructor) {
          case StartEntry:
            return <StartEntryRow entry={entry} key={entry.id} />;
          case StopEntry:
            return <StopEntryRow entry={entry} key={entry.id} />;
          case LocationEntry:
            return <LocationEntryRow entry={entry} key={entry.id} />;
          case LogEntry:
            return <LogEntryRow entry={entry} key={entry.id} />;
          case PermissionEntry:
            return <PermissionEntryRow entry={entry} key={entry.id} />;
          /* istanbul ignore next */
          default:
            return null;
        }
      }),
    [entries, applyFilter],
  );

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: '70px' }}>Time</TableCell>
          <TableCell align="center" sx={{ width: '40px' }}>
            Type
          </TableCell>
          <TableCell>Information</TableCell>
          <EmptyCell />
        </TableRow>
      </TableHead>

      <TableBody>{rows}</TableBody>
    </Table>
  );
};

ResultTable.propTypes = {
  filter: object,
};

export default ResultTable;
