import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonNext: {
    id: 'core.containers.ErrorReporter.button.next',
    description: 'Button text',
    defaultMessage: 'Next',
  },
  buttonSelect: {
    id: 'core.containers.ErrorReporter.button.select',
    description: 'Button text',
    defaultMessage: 'Select',
  },
  buttonBack: {
    id: 'core.containers.ErrorReporter.button.back',
    description: 'Button text',
    defaultMessage: 'Back',
  },
  buttonCancel: {
    id: 'core.containers.ErrorReporter.button.cancel',
    description: 'Button text',
    defaultMessage: 'Cancel',
  },
  buttonClose: {
    id: 'core.containers.ErrorReporter.button.close',
    description: 'Button text',
    defaultMessage: 'Close',
  },
  buttonSubmit: {
    id: 'core.containers.ErrorReporter.button.submit',
    description: 'Button text',
    defaultMessage: 'Submit',
  },
});
