import { storageKeys } from '~/hooks/useDevTools/constants';

const { THEME } = storageKeys;

export const resolveTheme = (theme) => {
  const devTheme = localStorage?.getItem(THEME);
  if (devTheme) {
    return devTheme;
  }

  return theme;
};
