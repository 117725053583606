import { func, string, bool } from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, Icon } from '@ccsdk/kingbolt';
import { ISSUE_TYPE_STEP, SELECT_VEHICLES_STEP, CONTACT_INFORMATION_STEP, SUBMIT_FORM_STEP } from '../../formSteps';
import messages from './messages';

const Buttons = ({ close, activeView, valid, handleClick, submit }) => {
  const { formatMessage } = useIntl();
  const nextView = 1;
  const previousView = -1;

  // First view: No 'back' button
  if (activeView === ISSUE_TYPE_STEP) {
    return (
      <>
        <Button data-cy-form-button="cancel" onClick={close}>
          {formatMessage(messages.buttonCancel)}
        </Button>
        <Button data-cy-form-button="next" disabled={!valid} onClick={() => handleClick(nextView)} variant="contained">
          {formatMessage(messages.buttonNext)}
        </Button>
      </>
    );
  }

  // Last view: No 'next' or 'back' button
  if (activeView === SUBMIT_FORM_STEP) {
    return (
      <Button data-cy-form-button="close" onClick={close}>
        {formatMessage(messages.buttonClose)}
      </Button>
    );
  }

  const getCorrectNextButton = () => {
    switch (activeView) {
      case SELECT_VEHICLES_STEP:
        return (
          <Button data-cy-form-button="select" disabled={!valid} onClick={() => handleClick(nextView)} variant="contained">
            {formatMessage(messages.buttonSelect)}
          </Button>
        );
      case CONTACT_INFORMATION_STEP:
        return (
          <Button
            data-cy-form-button="submit"
            disabled={!valid}
            onClick={() => {
              handleClick(nextView);
              submit();
            }}
            startIcon={<Icon type="checkmark" />}
            variant="contained"
          >
            {formatMessage(messages.buttonSubmit)}
          </Button>
        );
      default:
        return (
          <Button data-cy-form-button="next" disabled={!valid} onClick={() => handleClick(nextView)} variant="contained">
            {formatMessage(messages.buttonNext)}
          </Button>
        );
    }
  };

  return (
    <>
      <Button data-cy-form-button="cancel" onClick={close}>
        {formatMessage(messages.buttonCancel)}
      </Button>
      <Button data-cy-form-button="back" onClick={() => handleClick(previousView)} variant="outlined">
        {formatMessage(messages.buttonBack)}
      </Button>
      {getCorrectNextButton()}
    </>
  );
};

Buttons.propTypes = {
  activeView: string,
  close: func,
  handleClick: func,
  submit: func,
  valid: bool,
};

export default Buttons;
