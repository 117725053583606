/* eslint-disable no-console */
import { memo } from 'react';
import { Provider } from 'react-redux';
import { array, object, node } from 'prop-types';
import { addQueries } from '@ccsdk/core/ducks/queries';
import { addConfig } from '@ccsdk/core/ducks/config';
import { setCloudfrontMeta } from '@ccsdk/core/ducks/cloudfront';
import { setExternalDepsLoaded, addMeta } from '@ccsdk/core/ducks/dynamicModules';
import configureStore from '@ccsdk/core/configureStore';
import reducers from '~/components/ReduxProvider/reducers';
import { withProfiler } from '@sentry/react';
import { runQueries } from './queries';

import runSagas from './sagas';

const ReduxProvider = ({ queries, config, children, dynamicModules, cloudfrontMeta }) => {
  const store = configureStore({ initialState: {}, config, injectedReducers: reducers });

  console.debug('App Shell', 'ReduxProvider', 'Initializing');

  // console.debug('App Shell', 'ReduxProvider', 'Adding config');
  store.dispatch(addConfig(config));

  // console.debug('App Shell', 'ReduxProvider', 'Dispatching queries');
  store.dispatch(addQueries(queries));

  // console.debug('App Shell', 'ReduxProvider', 'Dispatching dynamic modules');
  store.dispatch(addMeta(dynamicModules));

  // console.debug('App Shell', 'ReduxProvider', 'Dispatching cloudfront meta');
  store.dispatch(setCloudfrontMeta(cloudfrontMeta));

  // console.debug('App Shell', 'ReduxProvider', 'Dispatching common deps');
  store.dispatch(setExternalDepsLoaded());

  // console.debug('App Shell', 'ReduxProvider', 'Injecting sagas');
  runSagas(store, {
    noAuth: config?.noAuth || false,
    noAuthLogin: config?.noAuthLogin || false,
  });

  // console.debug('App Shell', 'ReduxProvider', 'Injecting RTK queries');
  runQueries();

  console.debug('App Shell', 'ReduxProvider', 'Initialized');

  return <Provider store={store}>{children}</Provider>;
};

ReduxProvider.propTypes = {
  children: node,
  cloudfrontMeta: object,
  config: object,
  dynamicModules: array,
  queries: object,
};

export default memo(withProfiler(ReduxProvider, { name: 'ReduxProvider' }));
