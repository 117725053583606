import config from '@ccsdk/core/config';

const selectBrand = () => {
  switch (config.getBrand()) {
    case 'optifleet':
      return 'Optifleet';
    case 'caretrack':
      return 'CareTrack';
    case 'udtrucks':
    case 'udconnect':
      return 'My UD Fleet';
    case 'snacdealeraccess':
      return 'DOVE';
    case 'marketplace':
    case 'volvoconnect':
    case 'dynafleet':
    default:
      return 'Volvo Connect';
  }
};

export default selectBrand;
