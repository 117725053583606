import { func, number } from 'prop-types';
import { Drawer, Icon, List, ListItemButton, ListItemText } from '@ccsdk/kingbolt';
import { views } from '~/components/DevOverlay/constants';
import useDevTools from '~/hooks/useDevTools';

const { GENERAL, DATA, THEME, LOCAL_PERMISSIONS } = views;

const DevOverlayMenu = ({ view, setView }) => {
  const { scramblerPermission, themeSwitcherPermission, localPermissionsPermission } = useDevTools();

  return (
    <Drawer anchor="left" id="DevDialogNavigation" jss={{ maxWidth: '200px', minWidth: '200px' }} open responsive={false} size="200px">
      <List disablePadding>
        <ListItemButton onClick={() => setView(GENERAL)} selected={view === GENERAL}>
          <ListItemText primary="General" secondary="Redux, logs and debug" />
        </ListItemButton>
        <ListItemButton disabled={!scramblerPermission} onClick={() => setView(DATA)} selected={view === DATA}>
          <ListItemText meta={<Icon color="warning" type="warning" />} primary="Data Scrambler" secondary="Anonymize incoming data" />
        </ListItemButton>
        <ListItemButton disabled={!themeSwitcherPermission} onClick={() => setView(THEME)} selected={view === THEME}>
          <ListItemText primary="Theme" secondary="Look and feel" />
        </ListItemButton>
        <ListItemButton disabled={!localPermissionsPermission} onClick={() => setView(LOCAL_PERMISSIONS)} selected={view === LOCAL_PERMISSIONS}>
          <ListItemText primary="Local permissions" secondary="Enable/disable permissions" />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

DevOverlayMenu.propTypes = {
  setView: func,
  view: number,
};

export default DevOverlayMenu;
