import { forwardRef } from 'react';
import { Box, Typography } from '@ccsdk/kingbolt';

export const TooltipTitle = forwardRef((props, ref) => <Box display="flex" flexDirection="column" {...props} ref={ref} />);
export const TooltipRow = (props) => <Box display="flex" gap={2} justifyContent="space-between" {...props} />;

export const PhaseResultBody = forwardRef((props, ref) => (
  <Box alignItems="center" display="flex" flexDirection="column" gap={0.5} justifyContent="center" sx={{ position: 'relative' }} {...props} ref={ref} />
));

export const PhaseResultText = forwardRef((props, ref) => (
  <Typography sx={{ color: ({ palette }) => palette.grey[0] }} variant="caption" {...props} ref={ref} />
));
