import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 1em 1.5em;
`;

export const Heading = styled.h1`
  margin-bottom: 1em;
`;

export const Preamble = styled.p`
  margin-bottom: 1.5em;
`;

export const Message = styled.pre`
  color: #c24;
`;
