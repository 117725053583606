import { useState, useEffect } from 'react';
import { func } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCurrentSession } from '@ccsdk/core/hooks';
import { View, Box, Typography, TextField } from '@ccsdk/kingbolt';
import messages from './messages';

const ContactView = ({ handleChange }) => {
  const { formatMessage } = useIntl();
  const { user } = useCurrentSession();
  const [inputValue, setInputValue] = useState(user.email || '');

  useEffect(() => {
    handleChange(inputValue);
  }, [inputValue]);

  return (
    <View data-cy-view="contact-information" id="contact-information">
      <Box display="flex" flexDirection="column" gap={2} sx={{ padding: '16px' }}>
        <Typography variant="subtitle2">{formatMessage(messages.emailTitle)}</Typography>
        <Typography variant="body2">{formatMessage(messages.emailText)}</Typography>
        <TextField
          data-cy-textfield="contact-email"
          fullWidth
          id="contact-email"
          label={formatMessage(messages.emailTitle)}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={formatMessage(messages.textFieldPlaceholder)}
          required
          value={inputValue}
        />
      </Box>
    </View>
  );
};

ContactView.propTypes = {
  handleChange: func,
};

export default ContactView;
