import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'core.containers.CookieConsent.header',
    description: 'Header for cookie toaster',
    defaultMessage: '{name} USES COOKIES',
  },
  description: {
    id: 'core.containers.CookieConsent.description',
    description: 'Description on cookie toaster',
    defaultMessage:
      'We use cookies to drive our functionality and to improve the site experience. You can always update your cookie consent under My Account or read ' +
      'more about our cookies on the {link} page.',
  },
  subHeader: {
    id: 'core.containers.CookieConsent.subHeader',
    description: 'Sub header on cookie toaster',
    defaultMessage: 'PLEASE SELECT YOUR COOKIES CONSENT AND CONFIRM',
  },
  link: {
    id: 'core.containers.CookieConsent.linkToLegal',
    description: 'The text on the link to the legal page',
    defaultMessage: 'Cookies Statement',
  },
  statistics: {
    id: 'core.containers.CookieConsent.cookies.statistics.label',
    description: 'The text on the label of statistics checkbox',
    defaultMessage: 'Statistics Cookies (Recommended)',
  },
  necessary: {
    id: 'core.containers.CookieConsent.cookies.necessary.label',
    description: 'The text on the label of necessary checkbox',
    defaultMessage: 'Necessary Cookies (Required)',
  },
});
