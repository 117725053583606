import { string, func } from 'prop-types';
import { useIntl } from 'react-intl';
import { View, Radio, FormControl, FormControlLabel, RadioGroup } from '@ccsdk/kingbolt';
import messages from './messages';

const TypeOfIssueView = ({ issueType, handleChange }) => {
  const { formatMessage } = useIntl();

  return (
    <View data-cy-view="type-of-issue" id="type-of-issue">
      <FormControl sx={{ padding: '16px' }}>
        <RadioGroup aria-label="type-of-issue-radio-group" name="issue-type" onChange={(e) => handleChange(e.target.value)} value={issueType}>
          <FormControlLabel control={<Radio />} data-cy-radiobutton="vehicle-issue" label={formatMessage(messages.vehicleIssue)} value="vehicle-issue" />
          <FormControlLabel control={<Radio />} data-cy-radiobutton="other-issue" label={formatMessage(messages.otherIssue)} value="other-issue" />
        </RadioGroup>
      </FormControl>
    </View>
  );
};

TypeOfIssueView.propTypes = {
  handleChange: func,
  issueType: string,
};

export default TypeOfIssueView;
