import { memo, useEffect, useMemo } from 'react';
import useWebSocket from 'react-use-websocket';

const DevSocketListener = () => {
  console.debug('App Shell', 'Running in development mode, initiating dev socket');

  const url = useMemo(() => `ws://${location.host}/devsocket`, [location]);
  const { lastMessage } = useWebSocket(url, { shared: true });

  useEffect(() => {
    if (lastMessage) {
      console.debug('DevSocket', 'Got message', lastMessage.data);
      if (lastMessage.data === 'reload') {
        window.location.reload();
      }
    }
  }, [lastMessage]);

  return null;
};

export default memo(DevSocketListener);
