import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledToast = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  color: whitesmoke;
  z-index: 9900;
`;

export const ToastCenter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  ${({ theme: { spacing, breakpoints } }) => css`
    padding: ${spacing(2)} ${spacing(4)};
    ${breakpoints.down('sm')} {
      padding: ${spacing(1)} ${spacing(1)} ${spacing(1)} 0;
      align-self: center;
      justify-content: center;
      width: auto;
      text-align: center;
    }
  `};
`;

export const ToastMessage = styled.div`
  flex-direction: column;
  border-left: 5px solid ${({ theme }) => theme.palette.primary.main};
  padding-left: 20px;
`;
