import { defineMessages } from 'react-intl';

export default defineMessages({
  readMoreButton: {
    id: 'app-shell.globalnotification.readmore',
    description: 'Link to more information at system status',
    defaultMessage: 'Read more',
  },
  bannerMessage: {
    id: 'app-shell.globalnotification.banner',
    description: 'Message in banner',
    defaultMessage: 'We are experiencing disturbances and are actively working on solving the problem. Our apologies for any inconvenience this may cause.',
  },
});
