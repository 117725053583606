import { useDebug, useDebugSettings } from '@ccsdk/development-debug-utils';
import ProfilerResult from '~/components/Debug/DebugPortalWrapper/ResultDialog/ProfilingResult/ProfilerResult';
import { ProfilingResultContainer } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ProfilingResult/styles';

const ProfilingResult = () => {
  const { profilers } = useDebug();
  const { profilingEnabled } = useDebugSettings();

  if (!profilingEnabled || Object.keys(profilers).length === 0) {
    return null;
  }

  return (
    <ProfilingResultContainer>
      <ProfilerResult id="ccPortal" profiler={profilers.ccPortal} />
      {/* TODO: Add other profilers when finalized */}
    </ProfilingResultContainer>
  );
};

export default ProfilingResult;
