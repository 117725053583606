import { useCallback } from 'react';
import { func, bool } from 'prop-types';
import { IconButton, Typography } from '@ccsdk/kingbolt';

const ToggleCaseButton = ({ active, updateFilter }) => {
  const toggleCaseSensitive = useCallback(
    /* istanbul ignore next */ () => updateFilter((prev) => ({ ...prev, caseSensitive: !prev.caseSensitive })),
    [updateFilter],
  );

  return (
    <IconButton onClick={toggleCaseSensitive}>
      <Typography variant="body2" sx={{ fontWeight: active ? 500 : 400 }}>
        Aa
      </Typography>
    </IconButton>
  );
};

ToggleCaseButton.propTypes = {
  updateFilter: func.isRequired,
  active: bool,
};

export default ToggleCaseButton;
