import { object } from 'prop-types';
import { Wrapper, Preamble, Heading, Message } from './styles';

const AuthorizationError = ({ error }) => (
  <Wrapper>
    <Heading>Authorization Error</Heading>
    <Preamble>An error occurred when authorizing:</Preamble>
    <Message>{error.toString()}</Message>
  </Wrapper>
);

AuthorizationError.propTypes = {
  error: object,
};

export default AuthorizationError;
