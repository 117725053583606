export const isEmptyArray = (data) => Array.isArray(data) && data.length === 0;
export const isEmptyObject = (data) => data && typeof data === 'object' && !Array.isArray(data) && Object.keys(data).length === 0;

export const formatCode = (data) => {
  switch (typeof data) {
    case 'boolean':
      return Boolean(data).toString();
    case 'undefined':
      return 'undefined';
    default:
      return data;
  }
};
