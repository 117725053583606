import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@ccsdk/kingbolt';

const PermissionChip = ({ permission, onClick, onDelete }) => {
  const handleClick = useCallback(() => {
    onClick(permission);
  }, [onClick, permission]);

  const handleDelete = useCallback(() => {
    onDelete(permission);
  }, [onDelete, permission]);

  return (
    <Chip
      color={permission.enabled ? 'secondary' : 'default'}
      data-testid={`${permission.id}--${permission.enabled ? 'enabled' : 'disabled'}`}
      label={permission.id}
      onClick={handleClick}
      onDelete={handleDelete}
      size="small"
      variant="outlined"
    />
  );
};

PermissionChip.propTypes = {
  permission: PropTypes.shape({
    id: PropTypes.string,
    enabled: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PermissionChip;
