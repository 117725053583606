import { Suspense, useMemo } from 'react';
import { string, oneOfType, object, node, func } from 'prop-types';
import { useIntl } from 'react-intl';
import ErrorBoundary from '@ccsdk/core/components/ErrorBoundary';
import useDocumentTitle from '~/hooks/useDocumentTitle';
import { withProfiler } from '@sentry/react';

const startCase = (str = '') =>
  str
    .split(' ')
    .map((word = '') => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const wrapTool = (tool) => ({
  ...tool,
  wrappedComponent: <ToolWrapper component={tool.component} displayNameKey={tool?.displayNameKey} name={tool.name} />,
});

const ToolWrapper = ({ name, displayNameKey = '', component: Component }) => {
  const { formatMessage } = useIntl();

  const appTitle = useMemo(() => {
    try {
      return startCase(formatMessage({ id: displayNameKey }));
    } catch (error) {
      console.warn('App Shell', 'Routes', 'No translation for displayNameKey', error);
      return '';
    }
  }, [displayNameKey]);

  useDocumentTitle(appTitle);

  return (
    <ErrorBoundary.Regular key={name}>
      <Suspense fallback={null}>
        <Component />
      </Suspense>
    </ErrorBoundary.Regular>
  );
};

ToolWrapper.propTypes = {
  component: oneOfType([object, node, func]),
  displayNameKey: string,
  name: string,
};

export default withProfiler(ToolWrapper, { name: 'ToolWrapper' });
