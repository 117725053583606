import { useCallback, useState, memo } from 'react';
import { node } from 'prop-types';
import { Navigation } from '@ccsdk/kingbolt';
import useDevTools from '~/hooks/useDevTools';
import DebugPortalHeader from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader';
import DebugPortalBody from '~/components/Debug/DebugPortalWrapper/DebugPortalBody';
import ResultDialog from '~/components/Debug/DebugPortalWrapper/ResultDialog';
import InfoDialog from '~/components/Debug/DebugPortalWrapper/InfoDialog';

const DebugPortalWrapper = memo(({ children }) => {
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const { debugPermission, debugModeEnabled } = useDevTools();

  const toggleResultDialogOpen = useCallback(() => {
    setResultDialogOpen((prev) => !prev);
  }, []);

  const toggleInfoDialogOpen = useCallback(() => {
    setInfoDialogOpen((prev) => !prev);
  }, []);

  if (debugPermission && debugModeEnabled) {
    return (
      <Navigation>
        <DebugPortalHeader
          infoDialogOpen={infoDialogOpen}
          onToggleInfoDialog={toggleInfoDialogOpen}
          onToggleResultDialog={toggleResultDialogOpen}
          resultDialogOpen={resultDialogOpen}
        />
        <DebugPortalBody>{children}</DebugPortalBody>
        <ResultDialog onClose={toggleResultDialogOpen} open={resultDialogOpen} />
        <InfoDialog onClose={toggleInfoDialogOpen} open={infoDialogOpen} />
      </Navigation>
    );
  }

  return children;
});

DebugPortalWrapper.propTypes = {
  children: node,
};

export default DebugPortalWrapper;
