import { useEffect } from 'react';
import { node, object } from 'prop-types';
import dayjs from 'dayjs';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { makeSelectConfig } from '@ccsdk/core/ducks/config';
import { selectLanguage, selectLocale } from '@ccsdk/core/ducks/userSettings';
import { selectQueries } from '@ccsdk/core/ducks/queries';
import { isDevMode } from '@ccsdk/core/utils/url';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import updateLocale from 'dayjs/plugin/updateLocale';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localeData from 'dayjs/plugin/localeData';
import { defaultErrorHandler, formatDayJSLocale } from '~/components/LanguageProvider/utils';

import loadDayJSLocale from './dayjsLocales';
import getCalendarLocale from './calendarLocales';

if (!window.ccsdkGlobals) {
  window.ccsdkGlobals = {};
}

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);

const localeCalendarExtensionSelector = makeSelectConfig('localeCalendarExtension');

export const LanguageProvider = ({ messages, children }) => {
  const { locale: queryLocale, language: queryLanguage } = useSelector(selectQueries);
  const configLocale = useSelector(selectLocale);
  const configLanguage = useSelector(selectLanguage);
  const localeCalendarExtension = useSelector(localeCalendarExtensionSelector);

  const locale = queryLocale || configLocale;
  const language = queryLanguage || configLanguage;
  const extendedLocale = localeCalendarExtension ? `${locale}${localeCalendarExtension}` : locale;

  const loader = async () => {
    if (locale && language) {
      const formattedLocale = formatDayJSLocale(locale);
      console.debug('App Shell', 'LanguageProvider', 'Using locale ', locale, extendedLocale, formattedLocale);
      console.debug('App Shell', 'LanguageProvider', 'Using language ', language);

      try {
        // eslint-disable-next-line no-unused-vars
        const [dayJSLocale, calendarLocale] = await Promise.all([loadDayJSLocale(formattedLocale), getCalendarLocale(formattedLocale)]);

        dayjs.locale(formattedLocale);

        const resolvedCalendarLocale = calendarLocale?.default || calendarLocale;
        dayjs.updateLocale(formattedLocale, {
          calendar: resolvedCalendarLocale,
        });
      } catch (error) {
        console.warn('App Shell', 'LanguageProvider', 'There was an error loading dayjs locale', error);
      }
    }
  };

  useEffect(() => {
    loader();
  }, [locale, language]);

  useEffect(() => {
    window.ccsdkGlobals.locale = locale;
  }, [locale]);

  if (!locale || !language) {
    console.warn('App Shell', 'LanguageProvider', 'locale or language nor ready yet, waiting to render app');
    return null;
  }

  if (isDevMode()) {
    return (
      <IntlProvider key={extendedLocale} locale={extendedLocale} messages={messages[language]} onError={defaultErrorHandler} textComponent="span">
        {children}
      </IntlProvider>
    );
  }

  return (
    <IntlProvider key={extendedLocale} locale={extendedLocale} messages={messages[language]} textComponent="span">
      {children}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  children: node,
  messages: object,
};

export default LanguageProvider;
