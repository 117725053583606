import { string, bool, func } from 'prop-types';
import { ListItem, ListItemText, ListItemSecondaryAction, Switch, Link } from '@ccsdk/kingbolt';
import { SecondaryBox, Description } from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/SettingsButton/SettingListItem/styles';

const SettingListItem = ({ text, description, url, selected, onChange }) => {
  return (
    <ListItem>
      <ListItemText
        primary={text}
        secondary={
          <SecondaryBox>
            <Description>{description}</Description>
            <Link href={url} target="_blank">
              Read more
            </Link>
          </SecondaryBox>
        }
      />
      <ListItemSecondaryAction>
        <Switch checked={selected} edge="end" onChange={onChange} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

SettingListItem.propTypes = {
  description: string,
  onChange: func,
  selected: bool,
  text: string,
  url: string,
};

export default SettingListItem;
