import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Globals = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.ccsdkGlobals.navigate = navigate;
  }, [navigate]);
};

export default Globals;
