export const compareModules = (first, second) => {
  // Check if the arrays have the same number of objects
  if (first.length !== second.length) return false;

  // iterate over each object in the arrays
  for (let i = 0; i < first.length; i += 1) {
    // Check if the object's "name" properties are the same
    if (first[i].name !== second[i].name) return false;
  }

  // If we've made it this far, the arrays are equal
  return true;
};
