// Core
export const ERROR_REPORTER_PERMISSION = 'cup.reporterror';
export const NEW_WALKME_PERMISSION = 'ngpsgui.walkme.new';
export const ONE_TRUST_PERMISSION = 'cup.onetrust.enable';
export const COOKIE_PERMISSION = 'ngpsgui.cookies';

export const NGPS_USER_PERMISSION = 'ngpsgui.ngpsUser';
export const NGPS_NO_AUTH_PERMISSION = 'ngpsgui.noAuth';
export const DEVELOPER_PERMISSION = 'cup.developer';
export const DEVELOPER_SCRAMBLER_PERMISSION = 'cup.developer.scrambler';
export const DEVELOPER_THEME_PERMISSION = 'cup.developer.theme';
export const DEVELOPER_DEBUG_PERMISSION = 'cup.developer.debug';
export const DEVELOPER_LOCAL_PERMISSIONS_PERMISSION = 'cup.developer.localpermissions';
