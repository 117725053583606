import { useState } from 'react';
import { bool, func } from 'prop-types';
import { Typography, Button, Checkbox, FormControlLabel, FormGroup, Link } from '@ccsdk/kingbolt';
import { FormattedMessage } from 'react-intl';
import selectBrand from '~/components/CookieConsent/selectBrand';
import { StyledToast, ToastCenter, ToastMessage } from './styles';
import messages from './messages';

const CookieToaster = ({ approveCookie, noAuth }) => {
  const [statistics, setStatistics] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [necessary, setNecessary] = useState(true);
  const getAll = { ...{ necessary }, ...{ statistics } };
  const formattedFlavour = selectBrand().toUpperCase();

  return (
    <StyledToast active bottom data-test-cookie-toaster data-test-id="cookie-toaster" nature="default">
      <ToastCenter>
        <ToastMessage>
          <Typography color="white" component="h2" variant="subtitle2">
            <FormattedMessage {...messages.header} values={{ name: formattedFlavour }} />
          </Typography>
          <Typography color="white" gutterBottom variant="body1">
            <FormattedMessage
              values={{
                link: (
                  <Link component="a" data-test-cookie-link href={`/support/cookiesstatement${noAuth ? '?noAuth=true' : ''}`} target="_blank">
                    <FormattedMessage {...messages.link} />
                  </Link>
                ),
              }}
              {...messages.description}
            />
          </Typography>
          <Typography color="white" component="h2" variant="subtitle2">
            <FormattedMessage {...messages.subHeader} />
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={necessary} data-test-id="necessary-cookies-checkbox" />}
              label={<FormattedMessage {...messages.necessary} />}
              labelPlacement="end"
            />
            <FormControlLabel
              control={<Checkbox checked={statistics} data-test-id="statistics-cookies-checkbox" />}
              label={<FormattedMessage {...messages.statistics} />}
              labelPlacement="end"
              onClick={() => setStatistics(!statistics)}
            />
            <Button
              color="secondary"
              data-test-cookie-confirm-button
              data-test-id="cookie-confirm-button"
              onClick={() => approveCookie(getAll)}
              variant="contained"
            >
              Confirm
            </Button>
          </FormGroup>
        </ToastMessage>
      </ToastCenter>
    </StyledToast>
  );
};

CookieToaster.propTypes = {
  approveCookie: func.isRequired,
  noAuth: bool,
};

export default CookieToaster;
