import { func, bool } from 'prop-types';
import { Icon, Tooltip } from '@ccsdk/kingbolt';
import { useDebug } from '@ccsdk/development-debug-utils';
import { StyledIconButton } from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/styles';

const PreviousResultButton = ({ dialogOpen, onToggleDialog }) => {
  const { entries } = useDebug();

  if (dialogOpen || entries.length === 0) {
    return null;
  }

  return (
    <Tooltip title="Show previous result">
      <StyledIconButton onClick={onToggleDialog} sx={{ color: ({ palette }) => `${palette.grey[0]} !important` }}>
        <Icon type="spreadsheet" />
      </StyledIconButton>
    </Tooltip>
  );
};

PreviousResultButton.propTypes = {
  dialogOpen: bool,
  onToggleDialog: func,
};

export default PreviousResultButton;
