import { forwardRef, useEffect, useCallback, useState } from 'react';
import { bool } from 'prop-types';
import { useStopwatch } from 'react-timer-hook';

const StopWatch = forwardRef(({ running }, ref) => {
  const { start: startWatch, pause, reset, minutes, seconds } = useStopwatch({ autoStart: false });
  const [previous, setPrevious] = useState(null);

  const start = useCallback(() => {
    reset();
    startWatch();
    setPrevious(true);
  }, [startWatch, reset]);

  const stop = useCallback(() => {
    pause();
    reset();
    setPrevious(false);
  }, [pause, reset]);

  const zeroFill = useCallback((number) => (number < 10 ? `0${number}` : number), []);

  useEffect(() => {
    if (running !== previous) {
      if (running) {
        start();
      } else {
        stop();
      }
    }
  }, [running, previous, start, stop]);

  return <tt ref={ref}>{`${zeroFill(minutes)}:${zeroFill(seconds)}`}</tt>;
});

StopWatch.propTypes = {
  running: bool,
};

export default StopWatch;
