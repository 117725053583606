import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicleIssue: {
    id: 'core.containers.ErrorReporter.view.typeofissue.vehicleissue',
    description: 'Form option',
    defaultMessage: 'Vehicle/VIN issue',
  },
  otherIssue: {
    id: 'core.containers.ErrorReporter.view.typeofissue.otherissue',
    description: 'Form option',
    defaultMessage: 'Other issue',
  },
});
