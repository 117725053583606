import { Alert, AlertTitle, List } from '@ccsdk/kingbolt';
import useDevTools from '~/hooks/useDevTools';
import DevFeatureItem from '~/components/DevOverlay/DevOverlayContent/DevFeatureItem';
import ReloadNotice from '~/components/DevOverlay/DevOverlayContent/ReloadNotice';

const DataView = () => {
  const { scramblerPermission, dataScrambleEnabled, toggleDataScramble } = useDevTools();

  if (!scramblerPermission) {
    return null;
  }

  return (
    <>
      <Alert severity="warning" sx={{ borderRadius: 0 }}>
        <AlertTitle>IMPORTANT</AlertTitle>
        THIS FUNCTIONALITY CAN PERMANENTLY CORRUPT DATA and is ONLY intended for development and demo purposes!
        <br />
        DO NOT SAVE ANY DATA WHILE THIS FEATURE IS ENABLED.
      </Alert>
      <List>
        <DevFeatureItem
          description={
            <span>
              This will make a best-effort to intercept and anonymize all personal data sent by Volvo backends, such as user IDs, names, surnames, VINs etc.
              <br />
              <ReloadNotice />
            </span>
          }
          enabled={dataScrambleEnabled}
          name="Data Scrambler"
          toggle={toggleDataScramble}
        />
      </List>
    </>
  );
};

export default DataView;
