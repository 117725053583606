export const STATE_NAME = 'globalnotification';

export const FETCH_NOTIFICATIONS = `core/${STATE_NAME}/FETCH`;
export const FETCH_NOTIFICATIONS_SUCCESS = `core/${STATE_NAME}/FETCH/SUCCESS`;
export const FETCH_NOTIFICATIONS_ERROR = `core/${STATE_NAME}/FETCH/ERROR`;

export const CLOSE_NOTIFICATION = `core/${STATE_NAME}/CLOSE`;
export const CLOSE_NOTIFICATION_SUCCESS = `core/${STATE_NAME}/CLOSE/SUCCESS`;

export const closeNotification = ({ notificationId }) => ({
  type: CLOSE_NOTIFICATION,
  data: { notificationId },
});
