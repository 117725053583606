import { shape, number } from 'prop-types';
import { useIsMobile } from '@ccsdk/kingbolt';
import Counter from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/Counters/Counter';

const Counters = ({ statistics }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <Counter tooltip="Total number of entries" type="total" value={statistics.total} />
      {!isMobile && (
        <>
          <Counter tooltip="Number of permission calls where the current user did not have access." type="permission" value={statistics.permissions} />
          <Counter tooltip="Number of log warnings" type="warning" value={statistics.warnings} />
          <Counter tooltip="Number of log errors" type="error" value={statistics.errors} />
        </>
      )}
    </>
  );
};

Counters.propTypes = {
  statistics: shape({
    total: number,
    permissions: number,
    warnings: number,
    errors: number,
  }),
};

export default Counters;
