import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'core.containers.EmergencyMode.header',
    description: 'Header text for the emergency mode',
    defaultMessage: 'Emergency mode enabled',
  },
  info: {
    id: 'core.containers.EmergencyMode.info',
    description: 'Info text for the emergency mode',
    defaultMessage:
      'System is now in Emergency Mode. Standard authentication/authorization via the IDP have been disabled and you will need to provide an Emergency Key to use the current tool. Please note that most backend services do not support Emergency Mode, system functionality will therefore be strictly limited.',
  },
  input: {
    id: 'core.containers.EmergencyMode.input',
    description: 'Info text for the emergency mode key input',
    defaultMessage: 'Emergency key',
  },
  save: {
    id: 'core.containers.EmergencyMode.save',
    description: 'Text for the save button',
    defaultMessage: 'Save',
  },
});
