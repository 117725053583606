import { Box, Icon } from '@ccsdk/kingbolt';

const ReloadNotice = () => (
  <Box alignItems="center" component="span" display="flex" gap={1}>
    Changing this setting will reload the page.
    <Icon fontSize="small" type="refresh" />
  </Box>
);

export default ReloadNotice;
