import { defineMessages } from 'react-intl';

export default defineMessages({
  itemSelectTitle: {
    id: 'core.containers.ErrorReporter.view.selectvehicle.itemselect.title',
    description: 'Title',
    defaultMessage: 'Vehicle selection',
  },
  itemSelectSubtitle: {
    id: 'core.containers.ErrorReporter.view.selectvehicle.itemselect.subtitle',
    description: 'Subtitle',
    defaultMessage: 'selected',
  },
  itemSelectSelectAll: {
    id: 'core.containers.ErrorReporter.view.selectvehicle.itemselect.selectall',
    description: 'Text',
    defaultMessage: 'Select all',
  },
  itemSelectClearSelection: {
    id: 'core.containers.ErrorReporter.view.selectvehicle.itemselect.clearselection',
    description: 'Text',
    defaultMessage: 'Clear selection',
  },
});
