import { number } from 'prop-types';
import { Box, View, Views } from '@ccsdk/kingbolt';
import GeneralView from '~/components/DevOverlay/DevOverlayContent/GeneralView';
import DataView from '~/components/DevOverlay/DevOverlayContent/DataView';
import ThemeView from '~/components/DevOverlay/DevOverlayContent/ThemeView';
import LocalPermissionsView from '~/components/DevOverlay/DevOverlayContent/LocalPermissionsView';

const DevOverlayContent = ({ view }) => (
  <Box flexGrow={1}>
    <Views index={view} jump stretch>
      <View id="general">
        <GeneralView />
      </View>
      <View id="data">
        <DataView />
      </View>
      <View id="theme">
        <ThemeView />
      </View>
      <View id="local-permissions">
        <LocalPermissionsView />
      </View>
    </Views>
  </Box>
);

DevOverlayContent.propTypes = {
  view: number,
};

export default DevOverlayContent;
