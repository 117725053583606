import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'core.containers.ErrorReporter.view.details.title',
    description: 'Title',
    defaultMessage: 'Description',
  },
  descriptionText: {
    id: 'core.containers.ErrorReporter.view.details.text.description',
    description: 'Description text',
    defaultMessage: 'Try to be as detailed as possible. For example, what were you trying to do when the issue happened?',
  },
  textFieldLabel: {
    id: 'core.containers.ErrorReporter.view.details.textfield.label',
    description: 'Label for text field',
    defaultMessage: 'Issue description',
  },
  textFieldPlaceholder: {
    id: 'core.containers.ErrorReporter.view.details.textfield.placeholder',
    description: 'Placeholder for text field',
    defaultMessage: 'Describe issue',
  },
});
