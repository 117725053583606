const withNodeModules = (path) => `/node_modules/${path}`;

const ignoredPackages = ['react/', 'react-dom/', '@redux-saga/', '@ccsdk/'];

export const validatedStackframe = (stackframe) => {
  const ignored = ignoredPackages.some((ignore) => stackframe.fileName.includes(withNodeModules(ignore)));

  if (!ignored && stackframe.fileName.endsWith('.js')) {
    return stackframe;
  }

  return null;
};

export const createClipboardText = (stackframe) => {
  let fileName = stackframe.fileName;
  const indexOfApp = fileName.indexOf('/app/');
  if (indexOfApp) {
    fileName = fileName.substring(indexOfApp);
  }

  return `${fileName}:${stackframe.lineNumber}:${stackframe.columnNumber}`;
};
