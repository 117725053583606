import { string, shape } from 'prop-types';
import { ListItemSecondaryAction } from '@ccsdk/kingbolt';
import { ThemePreviewLayout, ColorBox } from './styles';

const ThemePreview = ({ colors }) => {
  const { primary, secondary, success, error, warning, info } = colors;
  return (
    <ListItemSecondaryAction>
      <ThemePreviewLayout>
        <ColorBox backgroundColor={primary} />
        <ColorBox backgroundColor={secondary} />
        <ColorBox backgroundColor={success} />
        <ColorBox backgroundColor={error} />
        <ColorBox backgroundColor={warning} />
        <ColorBox backgroundColor={info} />
      </ThemePreviewLayout>
    </ListItemSecondaryAction>
  );
};

ThemePreview.propTypes = {
  colors: shape({
    primary: string,
    secondary: string,
    success: string,
    error: string,
    warning: string,
    info: string,
  }),
};

export default ThemePreview;
