import { number, object, array, string, bool, oneOfType } from 'prop-types';
import { formatCode, isEmptyArray, isEmptyObject } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/LogEntryRow/utils';
import ExpandableData from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/ExpandableData';

const MessagePart = ({ data }) => {
  if (typeof data === 'string') {
    return <span>{data}</span>;
  }

  if (typeof data !== 'object') {
    return (
      <pre>
        <code>{formatCode(data)}</code>
      </pre>
    );
  }

  if (isEmptyArray(data)) {
    return (
      <pre>
        <code>[]</code>
      </pre>
    );
  }

  if (isEmptyObject(data)) {
    return (
      <pre>
        <code>{`{}`}</code>
      </pre>
    );
  }

  return <ExpandableData data={data} />;
};

MessagePart.propTypes = {
  data: oneOfType([number, object, array, string, bool]),
};

export default MessagePart;
