import { useMemo } from 'react';
import { array, func } from 'prop-types';
import { useIntl } from 'react-intl';
import { View, ItemSelect } from '@ccsdk/kingbolt';
import { useFleetVehicles } from '../../../../hooks/useFleetVehicles';
import messages from './messages';

const SelectVehicleView = ({ selectedVehicles, handleChange }) => {
  const { formatMessage } = useIntl();
  const { vehicles } = useFleetVehicles();

  const vehicleList = useMemo(() => vehicles.map((vehicle) => ({ id: vehicle.id, name: vehicle.name })), [vehicles]);

  return (
    <View data-cy-view="select-vehicle" id="select-vehicle">
      <ItemSelect
        data-cy-itemselect="select-vehicle"
        id="vehicle-item-select"
        includeDescriptionInSearch
        items={vehicleList}
        labels={{
          headerTitle: formatMessage(messages.itemSelectTitle),
          headerSubtitleItemSelection: formatMessage(messages.itemSelectSubtitle),
          itemClearSelection: formatMessage(messages.itemSelectClearSelection),
          itemSelectAll: formatMessage(messages.itemSelectSelectAll),
        }}
        onChange={(value) => handleChange(value)}
        selectedItems={selectedVehicles}
      />
    </View>
  );
};

SelectVehicleView.propTypes = {
  handleChange: func,
  selectedVehicles: array,
};

export default SelectVehicleView;
