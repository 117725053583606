import { func } from 'prop-types';
import { Icon, Tooltip } from '@ccsdk/kingbolt';
import { StyledIconButton } from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/styles';

const InfoButton = ({ onToggleDialog }) => (
  <Tooltip title="About debug mode">
    <StyledIconButton onClick={onToggleDialog}>
      <Icon type="infofilled" />
    </StyledIconButton>
  </Tooltip>
);

InfoButton.propTypes = {
  onToggleDialog: func,
};

export default InfoButton;
