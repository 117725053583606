import { useIntl } from 'react-intl';
import { Box, Alert } from '@ccsdk/kingbolt';
import messages from './messages';

const ErrorMessage = () => {
  const { formatMessage } = useIntl();
  return (
    <Box data-cy-error-message="submitted-error" sx={{ padding: '16px' }}>
      <Alert severity="error">{formatMessage(messages.errorTextAlert)}</Alert>
    </Box>
  );
};

export default ErrorMessage;
