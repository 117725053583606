import { useIntl } from 'react-intl';
import messages from './messages';

export const ISSUE_TYPE_STEP = 'issue-type-step';
export const SELECT_VEHICLES_STEP = 'select-vehicles-step';
export const ISSUE_DETAILS_STEP = 'issue-details-step';
export const CONTACT_INFORMATION_STEP = 'contact-information-step';
export const SUBMIT_FORM_STEP = 'submit-form-step';

const formSteps = (viewIndex, state) => {
  const { formatMessage } = useIntl();
  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Type of issue
  const isVehicleIssue = state.issueType === 'vehicle-issue';

  // Form validation
  const selectedVehiclesExist = state.selectedVehicles.length !== 0;
  const issueDetailsExist = state.issueDetails.length !== 0;
  const validEmail = emailRegEx.test(state.contactInformation);

  const steps = () => {
    // Steps for issue type: vehicle issue
    if (isVehicleIssue) {
      const stepsData = [
        { step: 1, view: ISSUE_TYPE_STEP, title: formatMessage(messages.typeOfIssueTitle), valid: true, success: false },
        {
          step: 2,
          view: SELECT_VEHICLES_STEP,
          title: formatMessage(messages.selectVehicleTitle),
          description: formatMessage(messages.vehicleIssue),
          valid: selectedVehiclesExist,
          success: false,
        },
        {
          step: 3,
          view: ISSUE_DETAILS_STEP,
          title: formatMessage(messages.detailsTitle),
          description: formatMessage(messages.vehicleIssue),
          valid: issueDetailsExist,
          success: false,
        },
        {
          step: 4,
          view: CONTACT_INFORMATION_STEP,
          title: formatMessage(messages.contactTitle),
          description: formatMessage(messages.vehicleIssue),
          valid: validEmail,
          success: false,
        },
        { step: 4, view: SUBMIT_FORM_STEP, title: formatMessage(messages.reportSubmitted), valid: true, success: true },
      ];
      return stepsData;
    }

    // Steps for issue type: other issue
    const stepsData = [
      { step: 1, view: ISSUE_TYPE_STEP, title: formatMessage(messages.typeOfIssueTitle), valid: true, success: false },
      {
        step: 2,
        view: ISSUE_DETAILS_STEP,
        title: formatMessage(messages.detailsTitle),
        description: formatMessage(messages.otherIssue),
        valid: issueDetailsExist,
        success: false,
      },
      {
        step: 3,
        view: CONTACT_INFORMATION_STEP,
        title: formatMessage(messages.contactTitle),
        description: formatMessage(messages.otherIssue),
        valid: validEmail,
        success: false,
      },
      { step: 3, view: SUBMIT_FORM_STEP, title: formatMessage(messages.reportSubmitted), valid: true, success: true },
    ];
    return stepsData;
  };

  const stepsData = steps();
  const { step, view, title, description, success, valid } = stepsData[viewIndex];
  const numberOfSteps = stepsData.length;

  return { step, view, title, description, success, valid, numberOfSteps };
};

export default formSteps;
