import { func, bool } from 'prop-types';
import { useDebug } from '@ccsdk/development-debug-utils';
import { HeaderContent, StyledHeader, StyledDivider, HeaderSection } from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/styles';
import StopWatch from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/StopWatch';
import PreviousResultButton from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/PreviousResultButton';
import Counters from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/Counters';
import SettingsButton from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/SettingsButton';
import InfoButton from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/InfoButton';
import RecordButton from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/RecordButton';

const DebugPortalHeader = ({ resultDialogOpen, onToggleResultDialog, onToggleInfoDialog }) => {
  const { recording, statistics } = useDebug();

  return (
    <StyledHeader>
      <HeaderContent>
        <HeaderSection>
          <RecordButton onToggleResultDialog={onToggleResultDialog} />
          {(recording && <StopWatch running={recording} />) || <PreviousResultButton dialogOpen={resultDialogOpen} onToggleDialog={onToggleResultDialog} />}
        </HeaderSection>

        <HeaderSection>
          {(recording && <Counters statistics={statistics} />) || (
            <>
              <InfoButton onToggleDialog={onToggleInfoDialog} />
              <StyledDivider />
              <SettingsButton />
            </>
          )}
        </HeaderSection>
      </HeaderContent>
    </StyledHeader>
  );
};

DebugPortalHeader.propTypes = {
  onToggleInfoDialog: func,
  onToggleResultDialog: func,
  resultDialogOpen: bool,
};

export default DebugPortalHeader;
