import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsTimings } from '@ccsdk/core/analytics/timings';
import { useConfig, useCloudFrontMeta } from '@ccsdk/core/hooks';
import { selectLoadedStatus, setLoaded } from '@ccsdk/core/ducks/status';
import { useStatisticsLogger } from '@ccsdk/core/containers/StatisticsLogger/hooks';

/* istanbul ignore next */
if (!window.ccsdkGlobals) {
  window.ccsdkGlobals = {};
}

const BootChecker = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector(selectLoadedStatus);
  const isTestBot = useConfig('isTestBot');
  const brand = useConfig('brand');
  const { country } = useCloudFrontMeta();
  const { stopTimer } = useAnalyticsTimings();
  const { recordStatistic } = useStatisticsLogger();

  const logBootStatistics = async () => {
    if (isLoaded && window.ccsdkGlobals.indexLoaded && !window.ccsdkGlobals.bootRecorded) {
      try {
        // Set this initially since we have no retries and don't really care if it fails
        window.ccsdkGlobals.bootRecorded = true;

        const bootTime = new Date().getTime() - window.ccsdkGlobals.indexLoaded;
        // const root = window.location.pathname.split('/').filter((item) => !!item)?.[0];
        // console.debug('App Shell', 'Boot', 'Dispatching boot time event to GA', bootTime, root);

        // Log to general statistics
        recordStatistic({
          url: 'portal_boot_time',
          country,
          brand,
          type: 'meta',
          responseTime: bootTime,
        });
      } catch (error) {
        console.warn('App Shell', 'Boot', 'Error when reporting boot time', error);
      }
    }
  };

  useEffect(() => {
    if (!isLoaded) {
      console.debug('App Shell', 'Boot', 'Setting boot status', true);
      dispatch(setLoaded({ value: true }));
    }

    if (!isTestBot) {
      console.debug('App Shell', 'Boot', 'Logging boot statistics');
      Promise.all([logBootStatistics(), stopTimer({ key: 'login_to_portal_loaded', context: 'session' })]);
    }
  }, [isLoaded]);

  return null;
};

export default BootChecker;
