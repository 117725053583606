import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { compareHeaderNodes } from '@ccsdk/core/utils/equality/compareHeaderNodes';
import ErrorBoundary from '@ccsdk/core/components/ErrorBoundary';
import { string } from 'prop-types';
import { selectHeaderNodes } from '~/components/Layout/selectors';

const HeaderNavigation = ({ drawerId }) => {
  const headerNodes = useSelector(selectHeaderNodes, compareHeaderNodes) || [];

  return (
    <Suspense fallback={null}>
      {headerNodes.map(({ component: Component, id }) => (
        <ErrorBoundary.Empty key={id}>
          <Component material portalReference={drawerId} />
        </ErrorBoundary.Empty>
      ))}
    </Suspense>
  );
};

HeaderNavigation.propTypes = {
  drawerId: string,
};

export default HeaderNavigation;
