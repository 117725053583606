import { forwardRef } from 'react';
import { string, bool, func } from 'prop-types';
import { Box, Icon, Link } from '@ccsdk/kingbolt';

const ExpandLink = forwardRef(({ text, expanded, onToggleExpanded }, ref) => (
  <Link onClick={onToggleExpanded} ref={ref}>
    <Box alignItems="center" display="flex" gap={0.5}>
      {text}
      <Icon fontSize="small" type={expanded ? 'chevronup' : 'chevrondown'} />
    </Box>
  </Link>
));

ExpandLink.propTypes = {
  expanded: bool,
  onToggleExpanded: func,
  text: string,
};

export default ExpandLink;
