import { defineMessages } from 'react-intl';

export default defineMessages({
  reportError: {
    id: 'core.components.reportableErrorPopup.reportError',
    description: '',
    defaultMessage: 'Create error report',
  },
  message: {
    id: 'core.components.reportableErrorPopup.message',
    description: '',
    defaultMessage: 'To generate an error report you can use when contacting support, please press the button below, or else close this window and try again',
  },
  messageNonInteractive: {
    id: 'core.components.reportableErrorPopup.message.nonInteractive',
    description: '',
    defaultMessage: 'If you contact support, please provide them with following error code.',
  },
});
