import { object } from 'prop-types';

export const HookName = ({ entry }) => <span>{entry.hook}</span>;
HookName.propTypes = {
  entry: object,
};

export const SinglePermission = ({ entry }) => <span>&quot;{entry.permissions[0]}&quot;</span>;
SinglePermission.propTypes = {
  entry: object,
};

export const Access = ({ entry }) => (
  <pre>
    <code>{Boolean(entry.access).toString()}</code>
  </pre>
);
Access.propTypes = {
  entry: object,
};
