import { bool, func } from 'prop-types';
import {
  Dialog,
  DialogContent,
  Header,
  HeaderTitle,
  Body,
  Footer,
  IconButton,
  Button,
  Icon,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@ccsdk/kingbolt';
import { LocationIcon, PermissionIcon } from '~/components/Debug/DebugPortalWrapper/styles';
import { StyledPaper } from '~/components/Debug/DebugPortalWrapper/InfoDialog/styles';

const InfoDialog = ({ open, onClose }) => (
  <Dialog PaperComponent={StyledPaper} open={open}>
    <DialogContent>
      <Header>
        <HeaderTitle>About debug mode</HeaderTitle>
        <IconButton onClick={onClose}>
          <Icon type="close" />
        </IconButton>
      </Header>
      <Body scroll>
        <List subheader={<ListSubheader disableGutters>When recording is started the following will be intercepted:</ListSubheader>}>
          <ListItem disableGutters>
            <ListItemIcon>
              <LocationIcon />
            </ListItemIcon>
            <ListItemText primary="Navigation/Location changes" secondary="Pathname + search" />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <Icon type="infofilled" />
            </ListItemIcon>
            <ListItemText primary="Logging" secondary="All levels (can be filtered in result)" />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <Icon type="layersfilled" />
            </ListItemIcon>
            <ListItemText primary="Stacktrace for call" secondary="Shows the stackframe that the entry originated from (reqiures sourcemap info)" />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <PermissionIcon />
            </ListItemIcon>
            <ListItemText primary="Permission checks" secondary="Permission + access result (only for newer permission hooks)" />
          </ListItem>
          <ListSubheader disableGutters>Local</ListSubheader>
          <ListItem disableGutters>
            <ListItemIcon>
              <Icon type="activityfilled" />
            </ListItemIcon>
            <ListItemText primary="Profiling (enabled in settings)" secondary="Mounts, updates, nested updates + base/actual times" />
          </ListItem>
        </List>
      </Body>
      <Footer>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </Footer>
    </DialogContent>
  </Dialog>
);

InfoDialog.propTypes = {
  onClose: func,
  open: bool,
};

export default InfoDialog;
