import { func } from 'prop-types';
import { useIntl } from 'react-intl';
import { View, Typography, Box, TextField } from '@ccsdk/kingbolt';
import messages from './messages';

const DetailsView = ({ handleChange }) => {
  const { formatMessage } = useIntl();

  return (
    <View data-cy-view="details" id="details">
      <Box display="flex" flexDirection="column" gap={2} sx={{ padding: '16px' }}>
        <Typography variant="subtitle2">{formatMessage(messages.title)}</Typography>
        <Typography variant="body2">{formatMessage(messages.descriptionText)}</Typography>
        <TextField
          data-cy-textfield="details"
          fullWidth
          id="issue-description"
          label={formatMessage(messages.textFieldLabel)}
          maxRows={5}
          multiline
          onChange={(e) => handleChange(e.target.value)}
          placeholder={formatMessage(messages.textFieldPlaceholder)}
          required
        />
      </Box>
    </View>
  );
};

DetailsView.propTypes = {
  handleChange: func,
};

export default DetailsView;
