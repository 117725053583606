/* eslint-disable no-console */

import { createRoot } from 'react-dom/client';
import Root from '~/components/Root';

export const startApp = ({ theme, queries = {}, config, dynamicModules, cloudfrontMeta }) => {
  console.info('App Shell', 'Boot', 'Starting app');
  const configJSON = config.allConfiguration() || {};
  const root = createRoot(document.getElementById('app'));
  root.render(<Root cloudfrontMeta={cloudfrontMeta} config={configJSON} dynamicModules={dynamicModules} queries={queries} theme={theme} />);
};
