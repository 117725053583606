import { useState, useMemo, useCallback } from 'react';
import { getClient, withScope, captureException } from '@sentry/browser';
import { useSelector } from 'react-redux';
import { selectErrorReporter } from '@ccsdk/core/ducks/errorReporter';
import { ERROR_REPORTER_PERMISSION } from '~/permissions';
import { useHasPermission } from '@ccsdk/core/hooks';

export const useErrorReporter = () => {
  const hasErrorReporterPermission = useHasPermission(ERROR_REPORTER_PERMISSION);
  const { visible } = useSelector(selectErrorReporter);
  const [trackingId, setTrackingId] = useState(null);
  const replay = getClient?.()?.getIntegrationByName('Replay');

  const showErrorReporter = useMemo(() => visible && hasErrorReporterPermission, [visible, hasErrorReporterPermission]);

  const reset = useCallback(() => {
    setTrackingId(null);
  }, []);

  const submit = async ({ issueType, selectedVehicles, issueDetails, contactInformation } = {}) => {
    console.info('Core', 'Error Reporter', 'Sending session replay to Sentry');
    withScope((scope) => {
      if (selectedVehicles.length) {
        scope.addAttachment({ filename: 'vehicles.txt', data: selectedVehicles.join(',') });
      }
      if (issueDetails) {
        scope.addAttachment({ filename: 'description.txt', data: issueDetails });
      }

      scope.setExtra('issueReport', true);
      scope.setExtra('contactInformation', contactInformation);
      scope.setExtra('vehicles', selectedVehicles);
      scope.setExtra('issueType', issueType);

      scope.setTag('issueReport', true);
      scope.setTag('issueType', issueType);
      scope.setTag('contactInformation', contactInformation);

      const replayId = replay?.getReplayId?.() || null;
      captureException(new Error(`User created an issue report: ${replayId}`));
      setTrackingId(replayId);
    });
  };

  return { trackingId, submit, reset, showErrorReporter };
};
