import { call, put } from 'redux-saga/effects';
import delay from '@redux-saga/delay-p';
import { authorize } from '@ccsdk/core/ducks/authentication';

const MAX_RETRIES = 3;

let retries = 0;

export function* handleAuthorizationError({ type, error }) {
  console.debug('App Shell', 'handling authorization error', type, error, retries);
  if (error.response && [401, 403].includes(error.response.status) && retries < MAX_RETRIES) {
    console.error('App Shell', 'caught bad response code from backend');
    yield call(delay, 1000);
    yield put(authorize());
    /* istanbul ignore next: complex to test */
    retries += 1;
  } else {
    console.error('App Shell', 'caught general auth error', error);
    /* eslint-disable-next-line no-undef */
    yield call(window.ccauth.redirectToLogin);
  }
}
