import { Views } from '@ccsdk/kingbolt';
import { object, number, func } from 'prop-types';
import { ISSUE_TYPE, SELECTED_VEHICLES, ISSUE_DETAILS, CONTACT_INFORMATION } from '../../reducer';
import TypeOfIssueView from './components/TypeOfIssueView/index';
import SelectVehicleView from './components/SelectVehicleView/index';
import DetailsView from './components/DetailsView/index';
import ContactView from './components/ContactView/index';
import SubmittedView from './components/SubmittedView/index';

export const FormViews = ({ state, viewIndex, updateFormData }) => {
  const { issueType, selectedVehicles, contactInformation } = state;

  if (issueType === 'vehicle-issue') {
    return (
      <Views index={viewIndex} stretch>
        <TypeOfIssueView handleChange={(typeOfIssue) => updateFormData(ISSUE_TYPE, typeOfIssue)} issueType={issueType} />
        <SelectVehicleView handleChange={(vehicles) => updateFormData(SELECTED_VEHICLES, vehicles)} selectedVehicles={selectedVehicles} />
        <DetailsView handleChange={(issueDetails) => updateFormData(ISSUE_DETAILS, issueDetails)} />
        <ContactView handleChange={(email) => updateFormData(CONTACT_INFORMATION, email)} />
        <SubmittedView email={contactInformation} />
      </Views>
    );
  }
  return (
    <Views index={viewIndex} stretch>
      <TypeOfIssueView handleChange={(typeOfIssue) => updateFormData(ISSUE_TYPE, typeOfIssue)} issueType={issueType} />
      <DetailsView handleChange={(issueDetails) => updateFormData(ISSUE_DETAILS, issueDetails)} />
      <ContactView handleChange={(email) => updateFormData(CONTACT_INFORMATION, email)} />
      <SubmittedView email={contactInformation} />
    </Views>
  );
};

FormViews.propTypes = {
  state: object,
  updateFormData: func,
  viewIndex: number,
};

export default FormViews;
