import { useSelector } from 'react-redux';
import { selectErrorPopup } from '@ccsdk/core/ducks/errorPopup';
import { isValidMessageObject } from '~/components/ErrorPopup/utils';
import ReportableErrorPopup from './ReportableErrorPopup';

const ErrorPopup = () => {
  const { messages, header, body } = useSelector(selectErrorPopup);

  if ((header && body) || isValidMessageObject(messages)) {
    return <ReportableErrorPopup />;
  }
  return null;
};

export default ErrorPopup;
