import { isLocalhost } from '@ccsdk/core/utils/url';
import { List, Divider } from '@ccsdk/kingbolt';
import { useDebugSettings } from '@ccsdk/development-debug-utils';
import { StyledIconButton } from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/styles';
import ButtonPopover from '~/components/Debug/DebugPortalWrapper/ButtonPopover';
import SettingListItem from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/SettingsButton/SettingListItem';
import { PopoverBody } from '~/components/Debug/DebugPortalWrapper/DebugPortalHeader/SettingsButton/styles';

const STRICT_MODE_URL = 'https://react.dev/reference/react/StrictMode';
const PROFILER_URL = 'https://react.dev/reference/react/Profiler';

const SettingsButton = () => {
  const localhost = isLocalhost();
  const { strictModeEnabled, profilingEnabled, toggleDebugStrictMode, toggleDebugProfiling } = useDebugSettings();

  return (
    <ButtonPopover ButtonComponent={StyledIconButton} badge={strictModeEnabled || profilingEnabled} iconType="settingsfilled" id="settingsPopover">
      <PopoverBody>
        <List disablePadding>
          <SettingListItem
            description="Lets you find common bugs in your components early during development."
            onChange={toggleDebugStrictMode}
            selected={strictModeEnabled}
            text="StrictMode"
            url={STRICT_MODE_URL}
          />
          {localhost ? (
            <>
              <Divider />
              <SettingListItem
                description="Lets you measure rendering performance of a React tree programmatically."
                onChange={toggleDebugProfiling}
                selected={profilingEnabled}
                text="Profiling"
                url={PROFILER_URL}
              />
            </>
          ) : null}
        </List>
      </PopoverBody>
    </ButtonPopover>
  );
};

export default SettingsButton;
