import { takeLatest, takeEvery, select } from 'redux-saga/effects';
import { SET_VISIBLE, SET_ONLINE } from '@ccsdk/core/ducks/status';
import { AUTHORIZATION_ERROR_OCCURRED, AUTHORIZE, USER_AUTHORIZED, SWITCH_ORGANIZATION, LAST_TOKEN_REFRESH } from '@ccsdk/core/ducks/authentication';
import { makeSelectConfig } from '@ccsdk/core/ducks/config';
import { authorize } from './authorize.saga';
import { delegateNextTokenRefresh } from './setupNextTokenRefresh.saga';
import { renewToken } from './renewToken.saga';
import { handleAuthorizationError } from './handleAuthorizationError.saga';
import { handleOnlineChange } from './handleOnlineChange.saga';
import { handleVisibilityChange } from './handleVisibilityChange.saga';
import { switchOrganization } from './switchOrganization.saga';

export default function* authSaga() {
  yield takeLatest(AUTHORIZE, authorize);
  const noAuth = yield select(makeSelectConfig('noAuth'));
  if (!noAuth) {
    yield takeLatest(USER_AUTHORIZED, delegateNextTokenRefresh);
    yield takeLatest(LAST_TOKEN_REFRESH, renewToken);
    yield takeLatest(AUTHORIZATION_ERROR_OCCURRED, handleAuthorizationError);
    yield takeEvery(SET_VISIBLE, handleVisibilityChange);
    yield takeEvery(SET_ONLINE, handleOnlineChange);
  }
  yield takeLatest(SWITCH_ORGANIZATION, switchOrganization);
}
