import { string, bool, func, node } from 'prop-types';
import { ListItem, ListItemText, Switch, ListItemSecondaryAction } from '@ccsdk/kingbolt';

const DevFeatureItem = ({ name, description, enabled, toggle }) => (
  <ListItem>
    <ListItemText primary={name} secondary={description} sx={{ paddingRight: '16px' }} />
    <ListItemSecondaryAction>
      <Switch checked={enabled} edge="end" onChange={toggle} />
    </ListItemSecondaryAction>
  </ListItem>
);

DevFeatureItem.propTypes = {
  description: node,
  enabled: bool,
  name: string,
  toggle: func,
};

export default DevFeatureItem;
