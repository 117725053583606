export const ISSUE_TYPE = 'issue-type';
export const SELECTED_VEHICLES = 'selected-vehicles';
export const ISSUE_DETAILS = 'issue-details';
export const CONTACT_INFORMATION = 'contact-information';
export const RESET_FORM = 'reset-form';

export const initialFormState = { issueType: 'vehicle-issue', selectedVehicles: [], issueDetails: '', contactInformation: '' };

const formReducer = (state, action) => {
  switch (action.type) {
    case ISSUE_TYPE:
      return { ...state, issueType: action.data };
    case SELECTED_VEHICLES:
      return { ...state, selectedVehicles: action.data };
    case ISSUE_DETAILS:
      return { ...state, issueDetails: action.data };
    case CONTACT_INFORMATION:
      return { ...state, contactInformation: action.data };
    case RESET_FORM:
      return { ...initialFormState };
    default:
      throw new Error('No match found');
  }
};

export default formReducer;
