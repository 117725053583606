import { defineMessages } from 'react-intl';

export default defineMessages({
  emailTitle: {
    id: 'core.containers.ErrorReporter.view.contact.title',
    description: 'Title',
    defaultMessage: 'Email adress',
  },
  emailText: {
    id: 'core.containers.ErrorReporter.view.contact.text.email',
    description: 'Email text',
    defaultMessage: 'In order to be contacted by the support, an email is needed.',
  },
  textFieldPlaceholder: {
    id: 'core.containers.ErrorReporter.view.contact.textfield.placeholder',
    description: 'Placeholder in text field',
    defaultMessage: 'Enter email adress',
  },
});
