import { object, string } from 'prop-types';
import PhaseResult from '~/components/Debug/DebugPortalWrapper/ResultDialog/ProfilingResult/ProfilerResult/PhaseResult';
import {
  PhaseResultContainer,
  ProfilerResultContainer,
  VerticalText,
} from '~/components/Debug/DebugPortalWrapper/ResultDialog/ProfilingResult/ProfilerResult/styles';

const ProfilerResult = ({ id, profiler }) => {
  if (!profiler) {
    return null;
  }

  return (
    <ProfilerResultContainer>
      <VerticalText text={id} />

      <PhaseResultContainer>
        <PhaseResult phase={profiler.mounts} title="Mounts" />
        <PhaseResult phase={profiler.updates} title="Updates" />
        <PhaseResult phase={profiler.nested} title="Nested" />
      </PhaseResultContainer>
    </ProfilerResultContainer>
  );
};

ProfilerResult.propTypes = {
  id: string,
  profiler: object,
};

export default ProfilerResult;
