import { object } from 'prop-types';
import { TableRow, Icon } from '@ccsdk/kingbolt';
import { IconCell, InfoCell, TimeCell, Strong, EmptyCell } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/styles';

const StartEntryRow = ({ entry }) => (
  <TableRow>
    <TimeCell>{entry.time}</TimeCell>
    <IconCell>
      <Icon type="activate" />
    </IconCell>
    <InfoCell>
      <Strong>Recording started</Strong> location: {entry.pathname}
    </InfoCell>
    <EmptyCell />
  </TableRow>
);

StartEntryRow.propTypes = {
  entry: object,
};

export default StartEntryRow;
