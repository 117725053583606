import { useMemo } from 'react';
import { object } from 'prop-types';
import { TableRow } from '@ccsdk/kingbolt';
import { logType } from '@ccsdk/development-debug-utils';
import { DebugIcon, ErrorIcon, InfoIcon, LogIcon, WarnIcon } from '~/components/Debug/DebugPortalWrapper/styles';
import { IconCell, InfoCell, TimeCell } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/styles';
import LogMessage from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/LogEntryRow/LogMessage';
import StackTraceCell from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/StackTraceCell';

const { INFO, ERROR, WARN, DEBUG } = logType;

const LogEntryRow = ({ entry }) => {
  const icon = useMemo(() => {
    switch (entry.type) {
      case INFO:
        return <InfoIcon />;
      case WARN:
        return <WarnIcon />;
      case ERROR:
        return <ErrorIcon />;
      case DEBUG:
        return <DebugIcon />;
      default:
        return <LogIcon />;
    }
  }, [entry]);

  return (
    <TableRow>
      <TimeCell>{entry.time}</TimeCell>
      <IconCell>{icon}</IconCell>
      <InfoCell>
        <LogMessage entry={entry} />
      </InfoCell>
      <StackTraceCell entry={entry} />
    </TableRow>
  );
};

LogEntryRow.propTypes = {
  entry: object,
};

export default LogEntryRow;
