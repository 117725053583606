import { getClient, captureException, withScope, addBreadcrumb } from '@sentry/browser';

const getReplayId = () => {
  return getClient?.()?.getIntegrationByName('Replay')?.getReplayId() || 'noreplay';
};

export const initCypress = () => {
  try {
    if (!window.Cypress?.cy) {
      return;
    }

    window.Cypress.cy.on('fail', (error, test) => {
      try {
        const replayId = getReplayId();
        withScope((sentryScope) => {
          console.warn('Cypress', 'Test', test.title, 'failed:', error.message);
          sentryScope.setTag('TestBotInternal', true);
          sentryScope.setTag('TestBotFailure', true);
          captureException(new Error(`TestBot run failed: ${test.title}, replay: ${replayId}`));
        });
      } catch (err) {
        console.warn('Cypress', 'failed to log fail event', err);
      }
      throw error;
    });

    window.Cypress.cy.on('log:added', ({ name, message }) => {
      try {
        console.info('Cypress', 'event', name, message);
        addBreadcrumb({
          type: 'info',
          level: 'info',
          category: 'cypress',
          message: `${name}: ${message}`,
        });
      } catch (err) {
        console.warn('Cypress', 'failed to log log:added event', err);
      }
    });

    window.Cypress.cy.on('test:before:run', (event) => {
      console.log('Cypress', 'test:before:run', event);
      try {
        console.info('Cypress', 'starting test', event.title);
        addBreadcrumb({
          type: 'info',
          level: 'info',
          category: 'cypress',
          message: `Starting test: ${event.title}`,
        });
      } catch (err) {
        console.warn('Cypress', 'failed to log test:before:run event', err);
      }
    });

    window.Cypress.cy.on('test:after:run', (event) => {
      try {
        const replayId = getReplayId();
        withScope((sentryScope) => {
          sentryScope.setTag('TestBotInternal', true);
          if (event.state === 'failed') {
            console.warn('Cypress', 'Test', event.title, 'failed:', event.err?.message);
            sentryScope.setTag('TestBotFailure', true);
            captureException(new Error(`TestBot run failed: ${event.title}, replay: ${replayId}`));
          } else {
            console.info('Cypress', 'Test finished', event.title);
            sentryScope.setTag('TestBotFailure', false);
            // captureMessage(`TestBot run finished: ${event.title}, replay: ${replayId}`);
          }
        });
      } catch (err) {
        console.warn('Cypress', 'failed to log test:after:run event', err);
      }
    });
  } catch (err) {
    console.warn('Problem setting up Cypress listener', err);
  }
};
