import { number } from 'prop-types';
import { CircularProgress, Typography } from '@ccsdk/kingbolt';
import {
  PhaseStatusContainer,
  PhaseStatusCount,
} from '~/components/Debug/DebugPortalWrapper/ResultDialog/ProfilingResult/ProfilerResult/PhaseResult/PhaseStatus/styles';

const SIZE = 100;
const THICKNESS = 10;

const PhaseStatus = ({ count, value }) => {
  return (
    <PhaseStatusContainer>
      <CircularProgress
        color={count === 0 ? 'grey' : 'success'}
        size={SIZE}
        thickness={THICKNESS}
        value={100}
        variant="determinate"
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
      <CircularProgress color="error" size={SIZE} thickness={THICKNESS} value={value} variant="determinate" />
      <PhaseStatusCount>
        <Typography component="div" sx={{ color: ({ palette }) => palette.grey[0] }} variant="subtitle1">
          {count}
        </Typography>
      </PhaseStatusCount>
    </PhaseStatusContainer>
  );
};

PhaseStatus.propTypes = {
  count: number,
  value: number,
};

export default PhaseStatus;
