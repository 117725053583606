import { useEffect } from 'react';
import { selectLoadedStatus } from '@ccsdk/core/ducks/status';
import { useSelector, useDispatch } from 'react-redux';
import { userAuthorized } from '@ccsdk/core/ducks/authentication';
import { useConfig } from '@ccsdk/core/hooks';

export const useNewTokenListener = () => {
  const isAppLoaded = useSelector(selectLoadedStatus);
  const dispatch = useDispatch();
  const noAuth = useConfig('noAuth');

  useEffect(() => {
    console.debug('App Shell', 'Auth', 'Starting ccauth token listener');
    if (!noAuth && window.ccauth?.onEvent && window.ccauth?.offEvent) {
      const listenerId = window.ccauth.onEvent('newTokenFetched', (tokenData) => {
        if (isAppLoaded) {
          console.debug('App Shell', 'Auth', 'Got token update from ccauth');
          dispatch(userAuthorized(tokenData));
        } else {
          console.debug('App Shell', 'Auth', 'App is not loaded yet, ignoring token data update');
        }
      });

      return () => {
        window.ccauth.offEvent(listenerId);
      };
    } else {
      console.warn('App Shell', 'Auth', 'ccauth not available or app is in noAuth, skipping token listener setup');
      return () => {};
    }
  }, [isAppLoaded]);
};
