import { getAPI } from '@ccsdk/core/utils';

export const fetchUserRoles = async (accessToken) => {
  const api = getAPI('users');
  const url = `${api}/currentuser/V1_0`;
  return fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.warn('App Shell', 'Auth', 'Error when fetching user roles, returning default object', error);
      return {};
    });
};
