import { useCallback } from 'react';
import { object, func } from 'prop-types';
import { HeaderDivider, List, ListSubheader, SearchBox } from '@ccsdk/kingbolt';
import FilterItem from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultFilter/FilterItem';
import { ErrorIcon, InfoIcon, LocationIcon, LogIcon, WarnIcon, PermissionIcon, CoreIcon, DebugIcon } from '~/components/Debug/DebugPortalWrapper/styles';
import ButtonPopover from '~/components/Debug/DebugPortalWrapper/ButtonPopover';
import ToggleCaseButton from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultFilter/ToggleCaseButton';
import { PopoverContainer, PopoverContent } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultFilter/styles';

const ResultFilter = ({ filter, updateFilter }) => {
  const internalUpdateFilter = useCallback(
    /* istanbul ignore next */ (prop, value) => {
      updateFilter((prev) => ({ ...prev, [prop]: value }));
    },
    [],
  );

  const onChange = useCallback(/* istanbul ignore next */ ({ target }) => internalUpdateFilter('text', target.value), []);

  return (
    <SearchBox inputProps={{ value: filter.text, onChange, placeholder: 'Filter' }} variant="underlined">
      <ToggleCaseButton active={filter.caseSensitive} updateFilter={updateFilter} />
      <HeaderDivider />
      <PopoverContainer>
        <ButtonPopover iconType="filter" id="filterSettingsPopover">
          <PopoverContent>
            <List dense subheader={<ListSubheader>Logging</ListSubheader>}>
              <FilterItem checked={filter.log} icon={<LogIcon />} onChange={internalUpdateFilter} primary="Log" secondary="Log level" />
              <FilterItem checked={filter.debug} icon={<DebugIcon />} onChange={internalUpdateFilter} primary="Debug" secondary="Log level" />
              <FilterItem checked={filter.info} icon={<InfoIcon />} onChange={internalUpdateFilter} primary="Info" secondary="Log level" />
              <FilterItem checked={filter.warning} icon={<WarnIcon />} onChange={internalUpdateFilter} primary="Warning" secondary="Log level" />
              <FilterItem checked={filter.error} icon={<ErrorIcon />} onChange={internalUpdateFilter} primary="Error" secondary="Log level" />
            </List>

            <List dense subheader={<ListSubheader>Portal</ListSubheader>}>
              <FilterItem
                checked={filter.permissions}
                icon={<PermissionIcon />}
                onChange={internalUpdateFilter}
                primary="Permissions"
                secondary="Permission check information"
              />
              <FilterItem
                checked={filter.navigation}
                icon={<LocationIcon />}
                onChange={internalUpdateFilter}
                primary="Navigation"
                secondary="Navigation information"
              />
              <FilterItem checked={filter.core} icon={<CoreIcon />} onChange={internalUpdateFilter} primary="Core" secondary="Core/portal information" />
            </List>
          </PopoverContent>
        </ButtonPopover>
      </PopoverContainer>
    </SearchBox>
  );
};

ResultFilter.propTypes = {
  filter: object.isRequired,
  updateFilter: func.isRequired,
};

export default ResultFilter;
