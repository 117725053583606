import { object } from 'prop-types';
import { TableRow } from '@ccsdk/kingbolt';
import { PermissionIcon } from '~/components/Debug/DebugPortalWrapper/styles';
import { IconCell, InfoCell, TimeCell } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/styles';
import StackTraceCell from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/StackTraceCell';
import ExpandableData from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/ExpandableData';
import { Access, HookName, SinglePermission } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/PermissionEntryRow/styles';

const PermissionEntryRow = ({ entry }) => {
  return (
    <TableRow>
      <TimeCell>{entry.time}</TimeCell>
      <IconCell>
        <PermissionIcon color={entry.access ? 'success' : 'warning'} />
      </IconCell>
      <InfoCell>
        <HookName entry={entry} />
        {(entry.permissions.length > 1 && <ExpandableData data={entry.permissions} />) || <SinglePermission entry={entry} />}
        <Access entry={entry} />
      </InfoCell>
      <StackTraceCell entry={entry} />
    </TableRow>
  );
};

PermissionEntryRow.propTypes = {
  entry: object,
};

export default PermissionEntryRow;
