import { memo, StrictMode } from 'react';
import { node } from 'prop-types';
import { Box } from '@ccsdk/kingbolt';
import { DebugProfiler, DebugOverrides, useDebugSettings } from '@ccsdk/development-debug-utils';

const DebugPortalBody = memo(({ children }) => {
  const { strictModeEnabled } = useDebugSettings();

  return (
    <>
      <DebugOverrides />
      <Box display="block" flexGrow={1} position="relative">
        <DebugProfiler profilerId="ccPortal">{(strictModeEnabled && <StrictMode>{children}</StrictMode>) || children}</DebugProfiler>
      </Box>
    </>
  );
});

DebugPortalBody.propTypes = {
  children: node,
};

export default DebugPortalBody;
