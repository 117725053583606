import { useAdobeAnalytics } from '@ccsdk/core/hooks';

export const useAnalytics = () => {
  const { pushComponentInteraction } = useAdobeAnalytics();

  const readmoreIncidentBannerAnalytics = (isLoggedIn, notificationId) => {
    const event = {
      parent: 'SystemStatus',
      name: 'systemstatus_read_more',
      displayText: 'Read more',
      action: 'link click',
      title: '',
      type: 'link',
      href: '/support/systemstatus',
      details: '',
      placement: 'banner',
      selectedOptions: [
        {
          attribute: 'isLoggedIn',
          value: isLoggedIn,
        },
        {
          attribute: 'id',
          value: notificationId,
        },
      ],
      componentInteraction: {
        value: 1,
      },
    };
    pushComponentInteraction(event);
  };

  const closeIncidentBannerAnalytics = (isLoggedIn, notificationId) => {
    const event = {
      parent: 'SystemStatus',
      name: 'systemstatus_close_incident_banner',
      displayText: '',
      action: 'button click',
      title: '',
      type: 'click',
      href: '',
      details: `The user is ${isLoggedIn ? 'logged in' : 'not logged in'}`,
      placement: 'banner',
      selectedOptions: [
        {
          attribute: 'isLoggedIn',
          value: isLoggedIn,
        },
        {
          attribute: 'id',
          value: notificationId,
        },
      ],
      componentInteraction: {
        value: 1,
      },
    };
    pushComponentInteraction(event);
  };

  const bannerHasBeenLoadedAnalytics = (isLoggedIn, notificationId) => {
    const event = {
      parent: 'SystemStatus',
      name: 'systemstatus_banner_has_been_loaded',
      displayText: '',
      action: '',
      title: '',
      type: 'banner',
      href: '',
      details: `The user is ${isLoggedIn ? 'logged in' : 'not logged in'}`,
      placement: 'top',
      selectedOptions: [
        {
          attribute: 'isLoggedIn',
          value: isLoggedIn,
        },
        {
          attribute: 'id',
          value: notificationId,
        },
      ],
      componentInteraction: {
        value: 1,
      },
    };
    pushComponentInteraction(event);
  };

  return {
    closeIncidentBannerAnalytics,
    readmoreIncidentBannerAnalytics,
    bannerHasBeenLoadedAnalytics,
  };
};
