import { List, Link } from '@ccsdk/kingbolt';
import DevFeatureItem from '~/components/DevOverlay/DevOverlayContent/DevFeatureItem';
import ReloadNotice from '~/components/DevOverlay/DevOverlayContent/ReloadNotice';
import useDevTools from '~/hooks/useDevTools';

const REDUX_DEV_TOOLS_URL = 'https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en';

const GeneralView = () => {
  const { reduxDevToolsEnabled, logsDisabled, toggleReduxDevTools, toggleLogsDisabled, debugPermission, debugModeEnabled, toggleDebugMode } = useDevTools();

  return (
    <List disablePadding>
      <DevFeatureItem
        description={
          <span>
            Disable passing logs to the console to avoid spam. If you enable logs, they will be passed and appear in the regular browser dev tools console as
            usual.
            <br />
            <ReloadNotice />
          </span>
        }
        enabled={logsDisabled}
        name="Disable logs"
        toggle={toggleLogsDisabled}
      />
      <DevFeatureItem
        description={
          <span>
            Enables the Redux Development Tools integration for Chrome Dev Tools.
            <br />
            (you still need to install the{' '}
            <Link href={REDUX_DEV_TOOLS_URL} target="_blank">
              Chrome extension
            </Link>{' '}
            separately)
            <br />
            <ReloadNotice />
          </span>
        }
        enabled={reduxDevToolsEnabled}
        name="Redux Dev Tools"
        toggle={toggleReduxDevTools}
      />
      {debugPermission ? (
        <DevFeatureItem
          description={
            <span>
              Enables a recording mode to get more information about certain error scenarios (e.g. reproducible errors).
              <br />
              <br />
              Will collect information about permissions, logs and map them to route changes.
            </span>
          }
          enabled={debugModeEnabled}
          name="Debug mode"
          toggle={toggleDebugMode}
        />
      ) : null}
    </List>
  );
};

export default GeneralView;
