export const isValidMessageObject = (messages = {}) => {
  if (typeof messages !== 'object') {
    return false;
  }

  if (messages.titleErrorMessage) {
    return true;
  }

  return Object.values(messages).some((entry) => typeof entry === 'object');
};
