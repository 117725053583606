const edgeCases = {
  'en-us': 'en',
};

const loadDayJSLocale = async (locale) => {
  const formattedLocale = edgeCases[locale] || locale;
  return import(/* webpackChunkName: "dayjs-locale" */ `dayjs/locale/${formattedLocale}.js`);
};

export default loadDayJSLocale;
