import { useState, useCallback, useReducer, useMemo } from 'react';
import { Body, Footer, Button, Icon, Alert, AlertTitle, Tooltip, List, ListItem, ListItemText, TextField, Box, IconButton } from '@ccsdk/kingbolt';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import useDevTools from '~/hooks/useDevTools';
import PermissionChip from '~/components/DevOverlay/DevOverlayContent/LocalPermissionsView/PermissionChip';

const reducer = (state, action) => {
  switch (action.type) {
    case 'post':
      return [{ id: action.payload, enabled: true }, ...state];
    case 'put':
      return state.map((permission) => (permission.id === action.payload.id ? action.payload : permission));
    case 'delete':
      return state.filter((permission) => action.payload !== permission);
    case 'reset':
      return action.payload;
    default:
      /* istanbul ignore next */
      return state;
  }
};

const LocalPermissionsView = () => {
  const { localPermissions, setLocalPermissions } = useDevTools();
  const [permissions, dispatch] = useReducer(reducer, localPermissions);
  const [value, setValue] = useState('');
  const [expanded, setExpanded] = useState(permissions.length < 10);
  const disabled = useMemo(() => isEqual(sortBy(permissions, 'id'), sortBy(localPermissions, 'id')), [permissions, localPermissions]);

  const handleApply = useCallback(() => {
    setLocalPermissions(permissions);
    window.location.reload();
  }, [permissions, setLocalPermissions]);

  const handleSubmit = useCallback(() => {
    if (value.length && !permissions.map((permission) => permission.id).includes(value)) {
      dispatch({ type: 'post', payload: value });
      setValue('');
    }
  }, [value, permissions, dispatch]);

  const handleChange = useCallback((event) => setValue(event.target.value.trim()), [setValue]);

  const handleReset = useCallback(() => dispatch({ type: 'reset', payload: localPermissions }), [dispatch, localPermissions]);

  const onDelete = useCallback((permission) => dispatch({ type: 'delete', payload: permission }), [dispatch]);

  const onClick = useCallback((permission) => dispatch({ type: 'put', payload: { ...permission, enabled: !permission.enabled } }), [dispatch]);

  const handleOnKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  const handleToggleExpand = useCallback(() => setExpanded(!expanded), [expanded]);

  return (
    <>
      <Alert
        action={
          <IconButton data-testid="expand-alert-button" onClick={handleToggleExpand}>
            <Icon type={expanded ? 'chevronup' : 'chevrondown'} />
          </IconButton>
        }
        severity="info"
        sx={{ borderRadius: 0 }}
      >
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <AlertTitle>Local permissions</AlertTitle>
        {expanded ? (
          <List dense disablePadding>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary="Permissions added here override permissions from elsewhere."
                secondary="Remove them from this place if you want your permissions to match other users with the same roles as you."
              />
            </ListItem>
            <ListItem disableGutters disablePadding>
              <ListItemText
                primary="These permissions exist only in your local storage."
                secondary="Changes you make will not affect the experience for other users."
              />
            </ListItem>
          </List>
        ) : null}
      </Alert>
      <Body>
        <TextField
          fullWidth
          helperText="Click on a permission to enable and disable it, or remove it by clicking the little X"
          inputProps={{ maxLength: 255 }}
          label="Add permission"
          onBlur={handleSubmit}
          onChange={handleChange}
          onKeyDown={handleOnKeyDown}
          placeholder="very.cool.permission"
          value={value}
        />
        <Body disablePadding scroll>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
            {permissions.map((permission) => (
              <PermissionChip key={permission.id} permission={permission} onClick={onClick} onDelete={onDelete} />
            ))}
          </Box>
        </Body>
      </Body>
      <Footer>
        <Tooltip title="Will reset any unapplied changes.">
          <Box>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <Button disabled={disabled} onClick={handleReset} variant="text">
              Reset
            </Button>
          </Box>
        </Tooltip>
        <Tooltip title="Will save the current changes and reload the portal.">
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <Button icon={<Icon type="refresh" />} onClick={handleApply} variant="contained">
            Apply
          </Button>
        </Tooltip>
      </Footer>
    </>
  );
};

export default LocalPermissionsView;
