export const defaultErrorHandler = /* istanbul ignore next */ () => {};

export const formatDayJSLocale = (locale) => {
  console.debug('App Shell', 'Intl', 'Formatting locale for dayJS', locale);
  if (locale === 'ja-JP') {
    return 'ja';
  }

  if (locale === 'sv-SE') {
    return 'sv';
  }

  if (locale === 'cs-CZ') {
    return 'cs';
  }

  if (locale === 'da-DK') {
    return 'da';
  }

  if (locale === 'no-NO') {
    return 'nb';
  }

  if (locale === 'ko-KR') {
    return 'ko';
  }

  if (locale === 'et-EE') {
    return 'et';
  }

  if (locale === 'in-ID') {
    return 'id';
  }

  if (locale === 'sl-SI') {
    return 'sl';
  }

  if (locale === 'ms-MY') {
    return 'ms';
  }

  if (locale === 'sr-BA') {
    return 'sr';
  }

  if (locale === 'zh-TW') {
    return 'zh';
  }

  if (!locale.includes('-')) {
    return locale;
  }

  const [languageComponent, localComponent] = locale.split('-');

  const formattedLanguageComponent = languageComponent.toLocaleLowerCase();
  const formattedLocalComponent = localComponent.toLocaleLowerCase();
  if (formattedLanguageComponent === formattedLocalComponent) {
    return languageComponent;
  }

  return `${formattedLanguageComponent}-${formattedLocalComponent}`;
};
