import { useEffect, useMemo } from 'react';
import useFetch from 'use-http';
import { useAPI, useAccessToken } from '@ccsdk/core/hooks';

export const useFleetVehicles = () => {
  const mdsAPI = useAPI('multidomainservices');
  const accessToken = useAccessToken();

  const { loading, error, data, post } = useFetch(mdsAPI, { headers: { Authorization: `Bearer ${accessToken}` } });

  const query = `query {
    vehicles(connectedStatus: [connected]) {
      id: platformVehicleIdentifier,
      name: vehicleName
    }
  }`;

  useEffect(() => {
    post('/graphql', { query });
  }, []);

  const vehicles = useMemo(() => {
    return data?.data?.vehicles || [];
  }, [data]);

  return { loading, error, vehicles };
};
