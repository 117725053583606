import styled from '@emotion/styled';
import { Button, CircularProgress as MUIProgress } from '@ccsdk/kingbolt';

export const StyledButton = styled(Button)`
  &&.MuiButtonBase-root {
    background-color: ${({ theme: { palette } }) => palette.grey[700]};
    color: ${({ theme: { palette } }) => palette.grey[0]} !important;
  }

  &&.MuiButtonBase-root:hover {
    background-color: ${({ theme: { palette } }) => palette.grey[600]};
  }
`;

export const CircularProgress = styled(MUIProgress)`
  &&.MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    color: inherit;
  }
`;
