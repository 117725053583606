import { memo, useEffect } from 'react';
import { node } from 'prop-types';
import { useConfig } from '@ccsdk/core/hooks';
import { Portal, PortalBody, PortalContent } from '@ccsdk/kingbolt/_internal';
import GlobalNotifications from '~/components/GlobalNotifications';
import Header from '~/components/Layout/Header';
import useInitialSetup from '~/hooks/useInitialSetup';
import { withProfiler } from '@sentry/react';

const Layout = ({ children }) => {
  const noHeader = useConfig('header.disable');
  const disableHeader = useConfig('noHeader');
  const showInitialSetup = useInitialSetup();

  useEffect(() => {
    console.debug('App Shell', 'Mounting Layout');
    return /* istanbul ignore next */ () => console.debug('App Shell', 'Unmounting Layout');
  }, []);

  return (
    <Portal>
      <GlobalNotifications />
      {!showInitialSetup && !noHeader && !disableHeader && <Header />}

      <PortalBody data-test-main>
        <PortalContent>{children}</PortalContent>
        <div id="portal-drawer-container" />
      </PortalBody>
    </Portal>
  );
};

Layout.propTypes = {
  children: node,
};

export default memo(withProfiler(Layout, { name: 'Layout' }));
