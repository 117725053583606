// colors from cc-gui: token-builder-cli/tokens/**/theme.json
export const themeConfigs = {
  volvoconnect: {
    name: 'Volvo connect (L)',
    colors: {
      primary: '#4A90E2',
      secondary: '#1251B5',
      success: '#1A6C31',
      error: '#D84451',
      warning: '#FDCD7C',
      info: '#4A90E2',
    },
  },
  volvoconnectna: {
    name: 'Volvo connect, North America (L)',
    colors: {
      primary: '#4A90E2',
      secondary: '#1251B5',
      success: '#37B599',
      error: '#D84451',
      warning: '#FDCD7C',
      info: '#4A90E2',
    },
  },
  volvobusconnect: {
    name: 'Volvo bus connect (L)',
    colors: {
      primary: '#4A90E2',
      secondary: '#1251B5',
      success: '#37B599',
      error: '#D84451',
      warning: '#FDCD7C',
      info: '#4A90E2',
    },
  },
  optifleet: {
    name: 'Optifleet (L)',
    colors: {
      primary: '#E3021B',
      secondary: '#252525',
      error: '#fe3e00',
      warning: '#ffaa00',
      success: '#00ba5e',
      info: '#0ad2ff',
    },
  },
  macktrucks: {
    name: 'Mack trucks (L)',
    colors: {
      primary: '#87754e',
      secondary: '#87754e',
      error: '#BF2026',
      warning: '#ffc107',
      success: '#00B19A',
      info: '#3f9eb9',
    },
  },
  caretrack: {
    name: 'Caretrack (L)',
    colors: {
      primary: '#F8CF54',
      secondary: '#1251B5',
      error: '#c13c48',
      warning: '#ffc107',
      success: '#00B19A',
      info: '#3564B4',
    },
  },
  udconnect: {
    name: 'UD trucks, International (L)',
    colors: {
      primary: '#D20A0F',
      secondary: '#3d8bf8',
      error: '#c13c48',
      warning: '#ffc107',
      success: '#00B19A',
      info: '#3564B4',
    },
  },
  snac: {
    name: 'SNAC (L)',
    colors: {
      primary: '#4A90E2',
      secondary: '#1251B5',
      success: '#37B599',
      error: '#D84451',
      warning: '#FDCD7C',
      info: '#4A90E2',
    },
  },
  dove: {
    name: 'DOVE (L)',
    colors: {
      primary: '#4A90E2',
      secondary: '#1251B5',
      success: '#3A8867',
      error: '#D84451',
      warning: '#FDCD7C',
      info: '#4A90E2',
    },
  },
  elegantconnect: {
    name: 'Elegant Connect (L)',
    colors: {
      primary: '#346559',
      secondary: '#1c6bba',
      success: '#1a6b31',
      error: '#bf2012',
      warning: '#ffa200',
      info: '#0b66d5',
    },
  },
  volvogroupdesign: {
    name: 'VGDS (L)',
    colors: {
      primary: '#003b8e',
      secondary: '#f7f7f7',
      success: '#125124',
      error: '#91160c',
      warning: '#bf7a00',
      info: '#003b8e',
    },
  },
  volvogroupdesignDark: {
    name: 'VGDS (D)',
    colors: {
      primary: '#a3c8fb',
      secondary: '#2d2d2d',
      success: '#9df7b7',
      error: '#faaea8',
      warning: '#402900',
      info: '#a3c8fb',
    },
  },
};
