/* eslint-disable no-restricted-syntax */
import { createSelector } from 'reselect';
import { makeSelectDynamicModuleMetaOfType, LOADED } from '@ccsdk/core/ducks/dynamicModules';
import { makeSelectConfig } from '@ccsdk/core/ducks/config';
import { selectPermission } from '@ccsdk/core/ducks/authentication';
import * as moduleBucket from '@ccsdk/core/utils/moduleBucket';
import { NEW_WALKME_PERMISSION } from '~/permissions';

export const byWeightDesc = ({ weight: weightA = 0 }, { weight: weightB = 0 }) => weightB - weightA;

export const selectHeaderConfig = makeSelectConfig('header');

export const selectWalkmePermission = selectPermission(NEW_WALKME_PERMISSION);

export const selectDynamicBaseHeaderNodes = makeSelectDynamicModuleMetaOfType('headerNode');

// TODO: simplify and remove legacy checks
const selectDynamicHeaderNodes = createSelector(selectDynamicBaseHeaderNodes, (baseDynamicNodes) =>
  baseDynamicNodes
    .filter(({ status }) => status === LOADED)
    .filter(({ inBucket }) => inBucket)
    .filter(({ key }) => moduleBucket.has(key))
    .map(({ key }) => moduleBucket.getByKey(key))
    .map(({ module }) => module)
    .filter((node) => !!node.id && !!node.component),
);

export const selectHeaderNodes = createSelector(
  selectDynamicHeaderNodes,
  selectHeaderConfig,
  selectWalkmePermission,
  // eslint-disable-next-line default-param-last
  (dynamicHeaderNodes = [], headerConfig, walkMePermission) => {
    return dynamicHeaderNodes
      .filter((node) => {
        switch (node.id) {
          case 'cp.menu.walkme':
            return walkMePermission && headerConfig.walkMe;
          case 'cp.menu.messaging':
            return headerConfig.messaging;
          case 'cp.menu.bell':
            return headerConfig.alerts || headerConfig.notifications;
          case 'cp.main.menu':
            return headerConfig.mainMenu;
          case 'cp.user.menu':
            return headerConfig.userMenu;
          default:
            return true;
        }
      })
      .sort(byWeightDesc);
  },
);
