import { useState, useCallback, useMemo } from 'react';
import { object, array, oneOfType } from 'prop-types';
import { Collapse, Box } from '@ccsdk/kingbolt';
import ExpandLink from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/ExpandLink';

// TODO: If only one key or array entry show it directly?
const ExpandableData = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => setExpanded((prev) => !prev), []);

  const type = useMemo(() => (Array.isArray(data) ? 'array' : 'object'), [data]);

  return (
    <Box display="inline-flex" flexDirection="column">
      <ExpandLink expanded={expanded} onToggleExpanded={toggleExpanded} text={type} />
      <Collapse in={expanded} timeout="auto">
        {expanded ? (
          <pre>
            <code>{JSON.stringify(data, null, '  ')}</code>
          </pre>
        ) : null}
      </Collapse>
    </Box>
  );
};

ExpandableData.propTypes = {
  data: oneOfType([object, array]),
};

export default ExpandableData;
