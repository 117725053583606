import { object } from 'prop-types';
import { templateContainsSubstitutionPattern } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/LogEntryRow/LogMessage/SubstitutionMessage/utils';
import SubstitutionMessage from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/LogEntryRow/LogMessage/SubstitutionMessage';
import MessagePart from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/LogEntryRow/LogMessage/MessagePart';

const LogMessage = ({ entry }) => {
  if (templateContainsSubstitutionPattern(entry.data)) {
    return <SubstitutionMessage entry={entry} />;
  }

  // eslint-disable-next-line react/no-array-index-key
  return entry.data.map((part, index) => <MessagePart data={part} key={`${entry.id}-${index}`} />);
};

LogMessage.propTypes = {
  entry: object,
};

export default LogMessage;
