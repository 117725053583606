import styled from '@emotion/styled';
import { Header, IconButton, Box, HeaderDivider } from '@ccsdk/kingbolt';

export const StyledHeader = styled(Header)`
  background-color: ${({ theme: { palette } }) => palette.grey[800]};
  color: ${({ theme: { palette } }) => palette.grey[0]};
  justify-content: center;
  flex-shrink: 0;
`;

export const StyledDivider = styled(HeaderDivider)`
  border-color: ${({ theme: { palette } }) => palette.grey[700]};
`;

export const HeaderContent = (props) => <Box alignItems="center" display="flex" justifyContent="space-between" sx={{ width: '900px' }} {...props} />;

export const HeaderSection = (props) => <Box alignItems="center" display="flex" gap={2} {...props} />;

export const StyledIconButton = styled(IconButton)`
  &&.MuiButtonBase-root {
    color: ${({ theme: { palette } }) => palette.grey[0]} !important;
  }

  &&.MuiButtonBase-root:hover {
    background-color: ${({ theme: { palette } }) => palette.grey[600]};
  }
`;
