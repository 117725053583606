import { string } from 'prop-types';
import { useIntl } from 'react-intl';
import { View, Box, Typography } from '@ccsdk/kingbolt';
import messages from './messages';

const SubmittedView = ({ email }) => {
  const { formatMessage } = useIntl();

  return (
    <View data-cy-view="report-submitted" id="report-submitted">
      <Box sx={{ padding: '16px' }}>
        <Typography>{formatMessage(messages.submittedText, { userEmail: <strong>{email}</strong> })}</Typography>
      </Box>
    </View>
  );
};

SubmittedView.propTypes = {
  email: string,
};

export default SubmittedView;
