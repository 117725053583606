/* eslint-disable react/no-unknown-property */

import { Helmet } from 'react-helmet';

const CookieConsentOneTrust = () => (
  <Helmet>
    <script
      charSet="UTF-8"
      data-domain-script="1355e6da-7f12-4af6-b4b9-e16bebdecad2-test"
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      type="text/javascript"
    ></script>
    <script type="text/javascript">{'function OptanonWrapper() {}'}</script>
  </Helmet>
);

export default CookieConsentOneTrust;
