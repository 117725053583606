import { useMemo } from 'react';
import { object } from 'prop-types';
import { TableRow } from '@ccsdk/kingbolt';
import { LocationIcon } from '~/components/Debug/DebugPortalWrapper/styles';
import { IconCell, InfoCell, TimeCell, Strong } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/styles';
import StackTraceCell from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/StackTraceCell';
import ExpandableData from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/ExpandableData';

const LocationEntryRow = ({ entry }) => {
  const searchData = useMemo(() => {
    if (entry.search) {
      const searchParams = new URLSearchParams(entry.search);
      searchParams.sort();
      const data = {};
      searchParams.forEach((value, key) => {
        data[key] = value;
      });
      return data;
    }
    return null;
  }, [entry.search]);

  return (
    <TableRow>
      <TimeCell>{entry.time}</TimeCell>
      <IconCell>
        <LocationIcon />
      </IconCell>
      <InfoCell>
        <span>New location:</span>
        <Strong>{entry.pathname}</Strong>
        {searchData ? (
          <>
            <span>Search:</span>
            <ExpandableData data={searchData} />
          </>
        ) : null}
      </InfoCell>
      <StackTraceCell entry={entry} />
    </TableRow>
  );
};

LocationEntryRow.propTypes = {
  entry: object,
};

export default LocationEntryRow;
