import { object } from 'prop-types';
import { TableRow, Icon } from '@ccsdk/kingbolt';
import { IconCell, InfoCell, TimeCell, Strong, EmptyCell } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/styles';

const StopEntryRow = ({ entry }) => (
  <TableRow>
    <TimeCell>{entry.time}</TimeCell>
    <IconCell>
      <Icon type="activateoff" />
    </IconCell>
    <InfoCell>
      <Strong>Recording stopped</Strong>
    </InfoCell>
    <EmptyCell />
  </TableRow>
);

StopEntryRow.propTypes = {
  entry: object,
};

export default StopEntryRow;
