import { memo } from 'react';
import { useSelector } from 'react-redux';
import { withProfiler } from '@sentry/react';
import { compareModules } from './utils';
import { selectFederatedTools, selectFederatedToolsLoading } from './selectors';
import Routes from './routes';

const Routing = memo(() => {
  const federatedTools = useSelector(selectFederatedTools, compareModules);
  const federatedToolsLoading = useSelector(selectFederatedToolsLoading);

  return <Routes federatedTools={federatedTools} federatedToolsLoading={federatedToolsLoading} />;
});

export default withProfiler(Routing, { name: 'Routing' });
