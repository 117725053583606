import { defineMessages } from 'react-intl';

export default defineMessages({
  incompatibleBrowserMessage: {
    id: 'core.incompatibleBrowser',
    description: 'Message to show when the users browser is incompatible with ccsdk',
    defaultMessage:
      'Your browser is not compatible with this version of the Customer Connect framework (CCSDK). We recommend that you install the latest version of Google Chrome, Microsoft Edge, Apple Safari, Mozilla Firefox or another modern browser. Please contact your IT support with questions about how to upgrade the browser on your workstation.',
  },
  commonInfo: {
    id: 'core.common.info',
    description: 'Common string',
    defaultMessage: 'info',
  },
  commonConfirm: {
    id: 'core.common.confirm',
    description: 'Common string',
    defaultMessage: 'confirm',
  },
  commonWarning: {
    id: 'core.common.warning',
    description: 'Common string',
    defaultMessage: 'warning',
  },
  commonOk: {
    id: 'core.common.ok',
    description: 'Common string',
    defaultMessage: 'ok',
  },
  commonCancel: {
    id: 'core.common.cancel',
    description: 'Common string',
    defaultMessage: 'cancel',
  },
  commonClose: {
    id: 'core.common.close',
    description: 'Common string',
    defaultMessage: 'close',
  },
  commonSave: {
    id: 'core.common.save',
    description: 'Common string',
    defaultMessage: 'save',
  },
  commonPrice: {
    id: 'core.common.price',
    description: 'Common string',
    defaultMessage: 'price',
  },
  commonDelete: {
    id: 'core.common.delete',
    description: 'Common string',
    defaultMessage: 'delete',
  },
  commonAuthenticating: {
    id: 'core.common.authenticating',
    description: 'Common string',
    defaultMessage: 'authenticating',
  },
  commonError: {
    id: 'core.common.error',
    description: 'Common string',
    defaultMessage: 'error',
  },
  commonReset: {
    id: 'core.common.reset',
    description: 'Common string',
    defaultMessage: 'reset',
  },
  commonUndo: {
    id: 'core.common.undo',
    description: 'Common string',
    defaultMessage: 'undo',
  },
  commonProceed: {
    id: 'core.common.proceed',
    description: 'Common string',
    defaultMessage: 'proceed',
  },
  commonYes: {
    id: 'core.common.yes',
    description: 'Common string',
    defaultMessage: 'yes',
  },
  commonNo: {
    id: 'core.common.no',
    description: 'Common string',
    defaultMessage: 'no',
  },
  commonName: {
    id: 'core.common.name',
    description: 'Common string',
    defaultMessage: 'name',
  },
  commonHelp: {
    id: 'core.common.help',
    description: 'Common string',
    defaultMessage: 'help',
  },
  commonBack: {
    id: 'core.common.back',
    description: 'Common string',
    defaultMessage: 'back',
  },
  commonSearch: {
    id: 'core.common.search',
    description: 'Common string',
    defaultMessage: 'search',
  },
  commonAcknowledgements: {
    id: 'core.common.acknowledgements',
    description: 'Common string',
    defaultMessage: 'acknowledgements',
  },
  commonRefresh: {
    id: 'core.common.refresh',
    description: 'Common string',
    defaultMessage: 'refresh',
  },
  commonAuthorizationCancelled: {
    id: 'core.common.authorizationCancelled',
    description: 'Common string',
    defaultMessage: 'authorization cancelled',
  },
  commonNoResults: {
    id: 'core.common.noResults',
    description: 'Common string',
    defaultMessage: 'no results',
  },
  commonSendFeedback: {
    id: 'core.common.sendFeedback',
    description: 'Common string',
    defaultMessage: 'send feedback',
  },
  commonfieldIsRequired: {
    id: 'core.common.fieldIsRequired',
    description: 'Common string',
    defaultMessage: 'field is required',
  },
  commonPleaseWait: {
    id: 'core.common.pleaseWait',
    description: 'Common string',
    defaultMessage: 'please wait...',
  },
  commonAnErrorOccurred: {
    id: 'core.common.AnErrorOccurred',
    description: 'Common string',
    defaultMessage: 'An error occurred',
  },
  disclaimerTitle: {
    id: 'core.disclaimer.title',
    description: 'Disclaimer title',
    defaultMessage: 'Disclaimer',
  },
  commonFailedToLoad: {
    id: 'core.common.failedToLoad',
    description: 'Failed to load',
    defaultMessage: "Failed to load information. Please make sure you're connected to the internet and try again.\nContact support if the problem continues.",
  },
  disclaimerTextTop: {
    id: 'core.disclaimer.textTop',
    description: 'Disclaimer text',
    defaultMessage:
      'The driver times shown in the Volvo Connect App shall be seen as a complement to the data presented in the tachograph or in the vehicles instrument cluster. The latter is more safe to use while driving and delivers frequently updated data.',
  },
  disclaimerTextMiddle: {
    id: 'core.disclaimer.textMiddle',
    description: 'Disclaimer text',
    defaultMessage:
      'The driver times shown in the Volvo Connect App can preferably be used to keep track of your driving and resting times when you are away from the vehicle.',
  },
  disclaimerTextBottom: {
    id: 'core.disclaimer.textBottom',
    description: 'Disclaimer text',
    defaultMessage:
      'The reliability of the times depend on the connectivity between the vehicle and Volvo Connect and how recent the driver card data has been downloaded.',
  },
  commonStartDemo: {
    id: 'core.common.startDemo',
    description: 'Common string',
    defaultMessage: 'Start demo',
  },
  commonMilesPerHour: {
    id: 'core.common.milesPerHour',
    description: 'Common string',
    defaultMessage: 'mph',
  },
  commonMilesPerGallon: {
    id: 'core.common.milesPerGallon',
    description: 'Common string',
    defaultMessage: 'mpg',
  },
  commonKilometersPerLiter: {
    id: 'core.common.kilometersPerLiter',
    description: 'Common string',
    defaultMessage: 'km/l',
  },
  commonKilometersPerUsGallon: {
    id: 'core.common.kilometersPerUsGallon',
    description: 'Common string',
    defaultMessage: 'km/US gallon',
  },
  commonMiles: {
    id: 'core.common.miles',
    description: 'Common string',
    defaultMessage: 'm',
  },
  commonMilesPerKilogram: {
    id: 'core.common.milesPerKilogram',
    description: 'Common string',
    defaultMessage: 'm/kg',
  },
  commonKilometersPerKilogram: {
    id: 'core.common.kilometersPerKilogram',
    description: 'Common string',
    defaultMessage: 'km/kg',
  },
  commonNoInternetConnection: {
    id: 'core.common.noInternetConnection',
    description: 'Common string',
    defaultMessage: 'No internet connection',
  },
  commonCheckConnection: {
    id: 'core.common.checkConnection',
    description: 'Common string',
    defaultMessage: 'Please check your connection or try again later',
  },
});
