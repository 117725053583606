import fetchSaga from '@ccsdk/core/utils/fetchData.saga';
import { dynamicModulesSaga } from '@ccsdk/core/ducks/dynamicModules';
import pushSaga from '@ccsdk/core/push/sagas';
import wnsSaga from '@ccsdk/core/pushSubscribe/sagas';
import authSaga from '~/components/Authentication/sagas';
import headerSaga from '~/components/Layout/sagas';
import noAuthSaga from '~/noAuthSaga';

export default function runSagas(store, options = {}) {
  const { runSaga } = store;
  const { noAuth, noAuthLogin } = options;

  if (noAuth) {
    runSaga(noAuthSaga);
    // Allow conditional login in noAuth mode
    if (noAuthLogin) {
      runSaga(authSaga);
    }
  } else {
    runSaga(authSaga);
  }

  runSaga(fetchSaga);
  runSaga(pushSaga);
  runSaga(dynamicModulesSaga);

  runSaga(wnsSaga);

  runSaga(headerSaga);
}
