import { useCallback, useState, useMemo } from 'react';
import { object } from 'prop-types';
import { Box, Collapse } from '@ccsdk/kingbolt';
import {
  applySubstitutionToMessage,
  splitLongMessages,
} from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/LogEntryRow/LogMessage/SubstitutionMessage/utils';
import ExpandLink from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/ExpandLink';

const SubstitutionMessage = ({ entry }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => setExpanded((prev) => !prev), []);

  const { short, more } = useMemo(() => splitLongMessages(applySubstitutionToMessage(entry.data)), [entry.data]);

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      {short}
      {more ? (
        <>
          <ExpandLink expanded={expanded} onToggleExpanded={toggleExpanded} text={expanded ? 'show less' : 'show more'} />
          <Collapse in={expanded} timeout="auto">
            {/* eslint-disable-next-line react/no-danger */}
            {expanded && more ? <span dangerouslySetInnerHTML={{ __html: more }} /> : null}
          </Collapse>
        </>
      ) : null}
    </Box>
  );
};

SubstitutionMessage.propTypes = {
  entry: object,
};

export default SubstitutionMessage;
