import { node } from 'prop-types';
import { TableCell, Box, Typography } from '@ccsdk/kingbolt';

export const EmptyCell = () => <TableCell width={50} />;

export const IconCell = ({ children }) => (
  <TableCell sx={{ verticalAlign: 'top' }}>
    <Box alignItems="center" display="flex" height="25px" justifyContent="center">
      {children}
    </Box>
  </TableCell>
);

IconCell.propTypes = {
  children: node,
};

export const TimeCell = ({ children }) => (
  <TableCell sx={{ verticalAlign: 'top' }}>
    <Box alignItems="center" display="flex" height="25px">
      {children}
    </Box>
  </TableCell>
);

TimeCell.propTypes = {
  children: node,
};

export const InfoCell = ({ children }) => (
  <TableCell sx={{ verticalAlign: 'top' }}>
    <Box display="flex" flexWrap="wrap" gap={2} sx={{ marginTop: '5px', '& > pre': { margin: 0 } }}>
      {children}
    </Box>
  </TableCell>
);

InfoCell.propTypes = {
  children: node,
};

export const Strong = ({ children }) => (
  <Typography component="span" sx={{ lineHeight: '16px' }} variant="subtitle2">
    {children}
  </Typography>
);

Strong.propTypes = {
  children: node,
};
