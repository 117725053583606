/**
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { useEffect, memo } from 'react';
import { translationMessages } from '@ccsdk/core-i18n';
import { SnackbarProvider } from 'notistack';
import { useDispatch } from 'react-redux';
import { useQueryParameter, useHasPermission, useIsLoggedIn, useIsTestMode, useBrand, useConfig } from '@ccsdk/core/hooks';
import ErrorBoundary from '@ccsdk/core/components/ErrorBoundary';
import StatisticsLogger from '@ccsdk/core/containers/StatisticsLogger';
import { isDevMode } from '@ccsdk/core/utils/url';
import { DebugProvider } from '@ccsdk/development-debug-utils';
import ErrorReporter from '~/components/ErrorReporter';
import DevSocketListener from '~/components/DevSocketListener';
import DevOverlay from '~/components/DevOverlay';
import ServiceLoader from '~/components/ServiceLoader';
import { ONE_TRUST_PERMISSION, COOKIE_PERMISSION, DEVELOPER_PERMISSION } from '~/permissions';
import ErrorPopup from '~/components/ErrorPopup';
import CookieConsentOneTrust from '~/components/CookieConsentOneTrust';
import LanguageProvider from '~/components/LanguageProvider';
import Layout from '~/components/Layout';
import LegacyWebsocketProvider from '~/components/LegacyWebsocketProvider';
import CookieConsent from '~/components/CookieConsent';
import Authentication from '~/components/Authentication';
import EmergencyMode from '~/components/EmergencyMode';
import Routing from '~/components/Routing';
import Globals from '~/components/Globals';
import DebugPortalWrapper from '~/components/Debug/DebugPortalWrapper';
import { useErrorReporter } from '~/components/ErrorReporter/hooks/useErrorReporter';
import { withProfiler } from '@sentry/react';
import { showErrorAction } from '@ccsdk/core/ducks/errorPopup';

const App = memo(() => {
  const brand = useBrand();
  const liveDomain = useConfig('liveDomain');
  const region = useConfig('region');
  const devMode = isDevMode();
  const isLoggedIn = useIsLoggedIn();
  const isTestMode = useIsTestMode();
  const hasCookiePermission = useHasPermission(COOKIE_PERMISSION);
  const hasOneTrustPermission = useHasPermission(ONE_TRUST_PERMISSION);
  const hasDeveloperPermission = useHasPermission(DEVELOPER_PERMISSION);
  const isSnac = brand === 'snac' || brand === 'snacdealeraccess';
  const isNA = region === 'na';
  const { showErrorReporter } = useErrorReporter();
  const dispatch = useDispatch();
  const simulateCrash = useQueryParameter('simulateCrash');

  useEffect(() => {
    console.info('App Shell', 'Loading main app section');
  }, []);

  useEffect(() => {
    if (simulateCrash === 'true') {
      dispatch(
        showErrorAction(123, 345, {
          titleErrorMessage: {
            id: 'core.containers.ErrorReporter.detailsLabel1',
            description: 'the label for the issue details input',
            defaultMessage: 'Oh no',
          },
          bodyErrorMessage: {
            id: 'core.containers.ErrorReporter.detailsLabel12',
            description: 'the label for the issue details input',
            defaultMessage: 'Shambles',
          },
        }),
      );
    }
  }, [simulateCrash]);

  return (
    <SnackbarProvider>
      <Authentication>
        <LanguageProvider messages={translationMessages}>
          {showErrorReporter ? <ErrorReporter /> : null}
          <ErrorPopup />
          <ErrorBoundary.Regular>
            <ServiceLoader />
            <Globals />
            {!isNA && !isSnac && !liveDomain && <LegacyWebsocketProvider />}
            {isLoggedIn && hasDeveloperPermission ? <DevOverlay /> : null}
            {devMode ? <DevSocketListener /> : null}
            {isLoggedIn ? <StatisticsLogger /> : null}
            <DebugProvider>
              <DebugPortalWrapper>
                <Layout>
                  <EmergencyMode>
                    <Routing />
                  </EmergencyMode>
                </Layout>
              </DebugPortalWrapper>
            </DebugProvider>
            {hasCookiePermission ? <CookieConsent /> : null}
            {hasOneTrustPermission && !isTestMode ? <CookieConsentOneTrust /> : null}
          </ErrorBoundary.Regular>
        </LanguageProvider>
      </Authentication>
    </SnackbarProvider>
  );
});

export default withProfiler(App, { name: 'App' });
