import { call, select } from 'redux-saga/effects';
import { selectLoadedStatus } from '@ccsdk/core/ducks/status';
import { renewToken } from './renewToken.saga';

export function* handleOnlineChange({ value }) {
  const isAppLoaded = yield select(selectLoadedStatus);
  if (isAppLoaded) {
    console.debug('App Shell', 'Auth', 'handling online change', value);
    if (value) {
      yield call(renewToken, { skipTokenCheck: true });
    }
  }
}
