import { string, shape, number } from 'prop-types';
import { Tooltip } from '@ccsdk/kingbolt';
import PhaseStatus from '~/components/Debug/DebugPortalWrapper/ResultDialog/ProfilingResult/ProfilerResult/PhaseResult/PhaseStatus';
import {
  TooltipRow,
  TooltipTitle,
  PhaseResultBody,
  PhaseResultText,
} from '~/components/Debug/DebugPortalWrapper/ResultDialog/ProfilingResult/ProfilerResult/PhaseResult/styles';
import { Strong } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/styles';

const PhaseResult = ({ title, phase }) => {
  const { count, lastActual, lastBase } = phase;

  const value = lastActual === 0 || count === 0 ? 0 : (lastActual / lastBase) * 100;

  return (
    <Tooltip
      title={
        (count > 0 && (
          <TooltipTitle>
            <TooltipRow>
              <span>Last Actual:</span>
              <Strong>{lastActual}</Strong>
            </TooltipRow>
            <TooltipRow>
              <span>Last Base:</span>
              <Strong>~{lastBase}</Strong>
            </TooltipRow>
            {value > 0 && (
              <TooltipRow>
                <span>Memoization failure:</span>
                <Strong>{`${Number.parseFloat(value).toFixed(2)}%`}</Strong>
              </TooltipRow>
            )}
          </TooltipTitle>
        )) ||
        null
      }
    >
      <PhaseResultBody>
        <PhaseStatus count={count} value={value} />

        <PhaseResultText>{title}</PhaseResultText>
      </PhaseResultBody>
    </Tooltip>
  );
};

PhaseResult.propTypes = {
  phase: shape({
    count: number,
    lastActual: number,
    lastBase: number,
  }),
  title: string,
};

export default PhaseResult;
